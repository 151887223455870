import {
  debounceTime,
  filter,
  map,
  switchMap,
  catchError,
  startWith,
  exhaustMap,
} from "rxjs/operators";
import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { SupportDataService } from "../../shared/support-data.service";
import { Subscription, of as observableOf } from "rxjs";
import { DockManagementService } from "../shared/dock-management.service";
import { environment } from "./../../../environments/environment.prod";
import { WSService2 } from 'src/app/shared/websocket/wss2.service';

@Component({
  selector: 'app-dock-assign-dialog',
  templateUrl: './dock-assign-dialog.component.html',
  styleUrls: ['./dock-assign-dialog.component.scss']
})
export class DockAssignDialogComponent {

  wsSubscription!:Subscription;
  msgTitle: string = "";
  msg: string = "";
  showMsg = true;
  bg: string = 'unset-bg';

  form = this.formBuilder.group({
    dock_space_id: ["", [Validators.required]],
  });

  optionsFilters: any = {
    dock_space: null,
  };

  dockFilterValue: string = "";
  dockFilter: UntypedFormControl = new UntypedFormControl();
  docks: any = [];
  searching = false;

  dockSpaces: any;
  spacesType: any[] = [];
  spaces = [];
  nameSpace: any;

  // Novo
  allDocks: any[] = []; // Nome de todas as docas
  vacantDocks: any[] = [];


  departments: any = [];
  hasDepartment = false;
  user: any;
  dockSpacesFiltered = [];
  department_id!: number;
  item: any;
  showComponent = false;
  docks_shared = false;


  constructor(
    private wssService: WSService2,
    private dockManagementService: DockManagementService,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    public supportData: SupportDataService,
    public dialogRef: MatDialogRef<DockAssignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const user = JSON.parse(localStorage.getItem("account")!);
    this.docks_shared = user.system_client.resource.environment.docks_shared;
    if (user) {
      this.user = user;
      if (this.user.department_id) {
        this.hasDepartment = true;
      }
    }
  }

  ngOnInit() {
    console.log('this.data ++-+-');
    console.log(this.data);
    console.log(this.docks_shared);
    document.addEventListener("visibilitychange", () => {
      if(document.visibilityState === 'visible') {
        this.wssService.closeConnection();
        this.subcribeWebsocket();
      }
    }, false);
    this.subcribeWebsocket();
    this.loadSpacesType();
    this.listnerDocks();
    this.initObservers();
  }

  getTitle(id: string) {
    return this.docks.find((dock: any) => dock.id === id)?.name || "";
  }

  initObservers() {
    this.form
      .get("dock_space_id")!
      .valueChanges.pipe(startWith(""))
      .subscribe((text) => (this.dockFilterValue = text));
  }

  listnerDocks() {
    const subscribe = this.dockFilter.valueChanges
      .pipe(
        debounceTime(800),
        filter((search) => search != "" || this.docks.length == 0),
        switchMap(() => {
          this.searching = true;
          const options = {
            sortedBy: "ASC",
            busy: false,
            maintenance: false,
            department_id: this.data.department_id,
            search: `${this.dockFilter.value}` || "",
          };
          return this.dockManagementService.index(options);
        }),
        map((response: any) => {
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        // this.docks =
        //   data instanceof Array
        //     ? data
        //     : data[this.data.department_id] || [];
          if(this.docks_shared) {
            this.docks = data;
          } else {
            this.docks = data.filter((item: any) => item.department_id === this.data.department_id);

          }
          this.msgTitle = 'Atualizado em tempo real';
          this.msg = `Existem ${this.docks.length} docas disponíveis`;
          this.showComponent = true;
          subscribe.unsubscribe()
      });
    this.dockFilter.setValue("", { emitEvent: true });
  }

  onSubmit() {
    console.log('submit');
    console.log('this.spacesType');
    console.log(this.spacesType);
    const dialogResult = JSON.parse(JSON.stringify(this.form.value));
    this.form.reset(this.form.value.dock_space_id);

    const dock = this.docks.find(
      (dock: any) => dock.id == dialogResult.dock_space_id
    );

    dialogResult.busy = true;
    dialogResult.origin_id = 2;
    dialogResult.origin_ref_id = this.data.item.id;
    dialogResult.release_dock_id = this.data.dock_space_id;
    dialogResult.release_ref_id = this.data.item.id;
    dialogResult.can_block = dock.can_block

    this.dockManagementService.update(dialogResult, dialogResult.dock_space_id).subscribe((r: any) => {
      if(r.error) {
        this.msgTitle = 'Erro ao atualizar!';
        this.msg = `${r.msg} Tente novamente.`;
      } else {
        delete dialogResult.origin_id;
        delete dialogResult.origin_ref_id;
        delete dialogResult.busy;

        // Propriedades necessárias para passar via check in
        dialogResult.dock_management_active = true;
        dialogResult.id = this.data.item.id;
        dialogResult.status = this.spacesType.find(
          (dockSpace: any) => dockSpace.id == dock.type
        )?.status;
        this.dialogRef.close(dialogResult);
      }
    });
    console.log(dialogResult);

  }

  //Busca os tipos de areas: docas, patios, ... spaces_type
  loadSpacesType() {
    this.supportData.spacesType().subscribe(
      (r: any) => {
        this.spacesType = r.data.resource;

        if (
          this.data.filterVehicleType &&
          this.data.filterVehicleType === true &&
          this.data.item.vehicle_type == 1
        ) {
          this.spacesType = this.spacesType.filter((item: any) => {
            if (item.id === "externas") {
              return true;
            } else {
              return false;
            }
          });
        }
      },
      (e: any) => {
        console.log("erro");
      }
    );
  }

  getDocks() {
    console.log('busca docas');
    console.log('departamentos');
    console.log(this.data.department_id);
    this.searching = true;
    const options = {
      orderBy: "id",
      sortedBy: "ASC",
      busy: false,
      maintenance: false,
      department_id: this.data.department_id,
      search: `${this.dockFilter.value}` || "",
    };
    this.dockManagementService.index(options).subscribe(
      (r: any) => {
        console.log('aqui');
        // this.docks =
        // r.data instanceof Array
        //   ? r.data
        //   : r.data[this.data.department_id];
        if(this.docks_shared) {
          this.docks = r.data;
        } else {
          this.docks = r.data.filter((item: any) => item.department_id === this.data.department_id);
        }

        this.showComponent = true;
      },
      (e: any) => {
        console.log("erro");
      }
    );
  }

  subcribeWebsocket() {
    let id = JSON.parse(localStorage.getItem("account")!).system_client.id;
    this.wssService.CHANNEL = `/docks/${id}`;
    this.wssService.connect().subscribe((msg: any) => {
      const wsMsg: any = Object.assign({}, msg)
      if(wsMsg.hasOwnProperty('action')){
        // Desativado temporariamente -> Necessita implementação na API do aplicativo
        // if(wsMsg.action === 1) {
        //   // Inclusão -> Define mensagem de inclusão
        //   if(wsMsg.msg) {
        //     this.snackBarMessage(wsMsg.msg);
        //   } else {
        //     this.snackBarMessage("Novo Check in realizado");
        //   }
        // }
        if(wsMsg.action === 2) {
          if(this.user.id != wsMsg.user_id) {
            console.log('busca as docas atualizadas')
            this.getDocks();
            // Update -> Define mensagem de update
            if(wsMsg.msg) {
              this.inlineMsg(wsMsg);
            } else {
              this.inlineMsg('Doca atualiza!');
            }
          }
        } else { // Após implementação na API do app, remover este trecho (else) e descomentar o trecho acima
          if(wsMsg.msg) {
            this.inlineMsg(wsMsg);
          } else {
            this.inlineMsg('Nova doca criada!');
          }
        }
      }
    });
  }

  inlineMsg(WsMsg: any) {
    // Checa se a doca que foi escolhida no form é a mesma da atualização... se for, limpar o formulário
    // e forçar o usuário
    this.bg = 'set-bg';
    let complementary_message = '';
    if(this.form.value.dock_space_id) {
      if((this.form.value.dock_space_id == WsMsg.dock_id) && WsMsg.busy) {
        complementary_message = 'Escolha outra para continuar.';
        this.form.reset(this.form.value.dock_space_id);
      }
    }
    this.msgTitle = 'Nova atualização recebida';
    this.msg = `${WsMsg.msg} ${complementary_message}`;
    setTimeout(()=>{
      this.bg = 'unset-bg';
      this.msgTitle = 'Atualizado em tempo real';
      this.msg = `Existem ${this.docks.length} docas disponíveis`;
    }, 4000);

  }

  ngOnDestroy(): void {
    this.wssService.closeConnection();
  }
}
