import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Schedule } from "../schedule";

@Injectable({
  providedIn: "root",
})
export class AgendamentoService {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  //Pega o time slot com horário minimo e máximo das janelas de agendamentos cadastradas
  //Utilizado no elemento que mostra a lista de horários em telas de agendamento (Ex : [09:00][10:00][11:00])
  time_slots(data: any) {
    const params = new HttpParams({ fromObject: data });
    const url = `${environment.apiUrl}/schedules/time_slots`;

    return this.http
      .get(url, { params })
      .pipe(
        catchError((err) => throwError(() => err || new Error("Server error"))),
      );
  }

  //Pega o time slot considerando se existir mais de uma janela cadastrada com intervalos diferentes, nesse caso as janelas serão combinadas
  multi_time_slots(data: any) {
    const params = new HttpParams({ fromObject: data });
    const url = `${environment.apiUrl}/schedules/multi_time_slots`;

    return this.http
      .get(url, { params })
      .pipe(
        catchError((err) => throwError(() => err || new Error("Server error"))),
      );
  }

  // time_slots() {
  //   const url = `${environment.apiUrl}/schedules/time_intervals`

  //   return this.http.get(url).pipe(catchError(err => throwError(() => err || new Error('Server error'))))
  // }

  scheduling_time(data: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/scheduling_time`;
    const params = new HttpParams({ fromObject: data });

    return this.http.get<Schedule>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  download(option: any) {
    const params = new HttpParams({ fromObject: option });

    const url = `${environment.apiUrl}/schedules/multidownload`;
    return this.http
      .get(url, { params })
      .pipe(
        catchError((err) => throwError(() => err || new Error("Server error"))),
      );
  }

  index(option: any) {
    const params = new HttpParams({ fromObject: option });
    const url = `${environment.apiUrl}/multischedules/list`;
    return this.http
      .get<any[]>(url, { params })
      .pipe(
        catchError((err) => throwError(() => err || new Error("Server error"))),
      );
  }

  create(data: any, params?: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/schedules`;
    const options = { params: params };

    return this.http.post<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  multicreate(data: any, params?: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/multischedules`;
    const options = { params: params };

    return this.http.post<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  multiupdate(data: any, id: string, params?: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/multischedules/${id}`;
    const options = { params: params };

    console.log(data);

    return this.http.put<Schedule>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  cancel(id: string | number) {
    const url = `${environment.apiUrl}/schedules/${id}/cancel`;
    return this.http
      .put(url, this.httpOptions)
      .pipe(
        catchError((err) => throwError(() => err || new Error("Server error"))),
      );
  }

  dinamicScheduleDeleteV1(id: string | number) {
    const url = `${environment.apiUrl}/genericschedule-v1/${id}`;
    return this.http
      .delete(url, this.httpOptions)
      .pipe(
        catchError((err) => throwError(() => err || new Error("Server error"))),
      );
  }

  dinamicScheduleCreate(data: any, params?: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/genericschedule`;
    const options = { params: params };

    return this.http.post<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  dinamicScheduleCreateV1(data: any, params?: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/genericschedule-v1`;
    const options = { params: params };

    return this.http.post<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  scheduleSlotValidation(options: any) {
    const params = new HttpParams({ fromObject: options });
    const url = `${environment.apiUrl}/schedule-slot-validation`;

    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  //Chama a API para gerar um código númerico aleatório de no máximo 8 digitos
  generateScheduleCodeV1() {
    const url = `${environment.apiUrl}/generateschedulecode-v1`;
    return this.http.get(url, { responseType: "text" }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  createScheduling(data: any, params?: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/newscheduling`;
    const options = { params: params };

    return this.http.post<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  updateScheduling(data: any, params?: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/newscheduling`;
    const options = { params: params };

    return this.http.put<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  dinamicScheduleUpdate(data: any, params?: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/genericschedule`;
    const options = { params: params };

    return this.http.put<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  dinamicScheduleUpdateV1(data: any, params?: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/genericschedule-v1`;
    const options = { params: params };

    return this.http.put<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  alterarStatus(data: any, params?: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/alterarStatus`;
    const options = { params: params };

    return this.http.put<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  alterarStatusMv(data: any, params?: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/alterarStatusMv`;
    const options = { params: params };

    return this.http.put<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  // Novo agendamento
  newScheduleIndex(option: any) {
    const params = new HttpParams({ fromObject: option });
    const url = `${environment.apiUrl}/schedules`;
    return this.http
      .get(url, { params })
      .pipe(
        catchError((err) => throwError(() => err || new Error("Server error"))),
      );
  }

  // fim novo agendamento

  createSchedulingBlock(data: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/newscheduling/block`;

    return this.http.post<any>(url, data, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  updateSchedulingBlock(data: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/newscheduling/block`;

    return this.http.put<any>(url, data, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  indexSchedulingBlock(): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/block`;
    return this.http.get<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  deleteSchedulingBlock(id: number): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/block/${id}`;
    return this.http.delete<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  createSchedulingLimit(data: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/newscheduling/limit`;

    return this.http.post<any>(url, data, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  updateSchedulingLimit(data: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/newscheduling/limit`;

    return this.http.put<any>(url, data, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  indexSchedulingLimit(): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/limit`;
    return this.http.get<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  deleteSchedulingLimit(id: number): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/limit/${id}`;
    return this.http.delete<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  createSchedulingAntecedence(data: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/newscheduling/antecedence`;

    return this.http.post<any>(url, data, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  updateSchedulingAntecedence(data: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/newscheduling/antecedence`;

    return this.http.put<any>(url, data, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  indexSchedulingAntecedence(): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/antecedence`;
    return this.http.get<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  deleteSchedulingAntecedence(id: number): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/antecedence/${id}`;
    return this.http.delete<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  // Refactory methods
  storeScheduleWindow(data: any): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/window`;

    return this.http.post<any>(url, data, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  updateScheduleWindow(data: any): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/window`;

    return this.http.put<any>(url, data, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  indexSchedulingWindow(): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/window`;
    return this.http.get<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  deleteSchedulingWindow(id: number): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/window/${id}`;
    return this.http.delete<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  indexSchedulingStatus(): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/status`;
    return this.http.get<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  indexPreSchedulingStatus(): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/prestatus`;
    return this.http.get<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  //Função que verifica se o agendamento já possui seu ID
  //Em um registro de jornada em driver_checkin
  getCheckinIdWithEvent(data: any): Observable<any> {
    const params = new HttpParams({ fromObject: data });
    const url = `${environment.apiUrl}/getcheckinidwithevent-v1`;
    return this.http.get<any>(url, { params }).pipe(
      map((response: Response) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  getSchedulingActions(data: any): Observable<any> {
    const params = new HttpParams({ fromObject: data });
    const url = `${environment.apiUrl}/newscheduling/status-actions`;
    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  storeSchedulingStatus(data: any): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/status`;

    return this.http.post<any>(url, data, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  //COLOCAR OS STORE/UPADTE/DELETE DOCUMENTOS NA SERVICE DE DOCUMETOS
  storePreSchedulingStatus(data: any): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/prestatus`;

    return this.http.post<any>(url, data, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  updateSchedulingStatus(data: any): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/status`;

    return this.http.put<any>(url, data, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  updatePreSchedulingStatus(data: any): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/prestatus`;

    return this.http.put<any>(url, data, {}).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  deleteSchedulingStatus(id: number): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/status/${id}`;
    return this.http.delete<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  deletePreSchedulingStatus(id: number): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/prestatus/${id}`;
    return this.http.delete<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  getEntrySchedules(data: any, status: string): Observable<any> {
    const combinedParams = { ...data, status };
    const params = new HttpParams({ fromObject: combinedParams });
    const url = `${environment.apiUrl}/newscheduling/approved`;
    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response.data || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  createPreSchedule(data: any, params?: any): Observable<Schedule> {
    const url = `${environment.apiUrl}/pre-schedules`;
    const options = { params: params };

    return this.http.post<Document>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  print(id: number): Observable<any> {
    const url = `${environment.apiUrl}/newscheduling/print/${id}`;
    return this.http.get<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  downloadPDF(document: string, id: number): Observable<Blob> {
    const options = { responseType: "blob" as "json" };
    const url = `${environment.apiUrl}/newscheduling/print/${document}/${id}`;
    return this.http
      .get<Blob>(url, options)
      .pipe(map((res) => new Blob([res], { type: "application/pdf" })));
  }
}
