import { AgendamentoService } from "./../../../multiagendamento/shared/agendamento.service";
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { SupportDataService } from "src/app/shared/support-data.service";

@Component({
  selector: "app-schedule-window-by-weight-dialog",
  templateUrl: "./schedule-window-by-weight-dialog.component.html",
  styleUrls: ["./schedule-window-by-weight-dialog.component.scss"],
})
export class ScheduleWindowByWeightDialogComponent {
  weightRangeList: any[] = [];
  form = this.formBuilder.group({
    weight_range_id: [this.data.weight_range, Validators.required],
    start: [this.data.start, Validators.required],
    end: [this.data.end, Validators.required],
    slot: [this.data.slot, Validators.required],
    limitBySlotSize: [this.data.limitbyslotsize],
    limitScheduleSlot: [this.data.limitscheduleslot],
  });

  validationMsgs: any[] = [];
  account: any;
  allDataLoaded = false;
  nonEditableField = false;

  constructor(
    public dialogRef: MatDialogRef<ScheduleWindowByWeightDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    private supportDataService: SupportDataService,
    private agendamentoService: AgendamentoService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.getWeightRange();
  }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem("account") || "{}");
  }

  close() {
    this.dialogRef.close();
  }

  setLimitBySlotSize(value: boolean | null) {
    if (value) {
      this.form.get("limitScheduleSlot")!.setValidators(Validators.required);
    } else {
      this.form.get("limitScheduleSlot")!.clearValidators();
    }
    this.cdr.detectChanges();
  }

  onSubmit() {
    const options = {
      id: this.data.id ? this.data.id : null,
      resource: {
        weight_range_id: this.form.value.weight_range_id,
        start: this.form.value.start,
        end: this.form.value.end,
        slot: this.form.value.slot,
        limitBySlotSize: this.form.value.limitBySlotSize,
        limitScheduleSlot: this.form.value.limitBySlotSize
          ? this.form.value.limitScheduleSlot
          : null,
      },
    };

    if (options.id) {
      this.agendamentoService
        .updateScheduleWindowByWeight(options)
        .subscribe((r: any) => {
          if (r.error) {
            this.validationMsgs = r.msg;
          } else {
            this.close();
          }
        });
    } else {
      this.agendamentoService
        .storeScheduleWindowByWeight(options)
        .subscribe((r: any) => {
          if (r.error) {
            this.validationMsgs = r.msg;
          } else {
            this.close();
          }
        });
    }
  }

  getWeightRange() {
    this.supportDataService
      .generic({ name: "scheduling_weight_range" })
      .subscribe((r: any) => {
        this.weightRangeList = r.data.resource;
      });
  }
}
