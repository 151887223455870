<ng-container
  *ngIf="account.system_client.resource.environment.send_email_after_scheduling !== false; else elseTemplateStepper"
>
  <h2>{{ data.dialogAction }}</h2>
  <div *ngIf="validationMsgs.length > 0" class="alert-denied-list">
    <h4>
      <b>{{ "Business_model.attention" | translate:{Default: "Atenção"} }}</b>
    </h4>
    <p *ngFor="let validate of validationMsgs">{{ validate }}</p>
  </div>
  <form [formGroup]="form">
    <mat-horizontal-stepper [linear]="true" labelPosition="bottom" #stepper>
      <mat-step [stepControl]="form">
        <ng-template matStepLabel
          >{{ "Business_model.datas" | translate:{Default: "Dados"}
          }}</ng-template
        >

        <mat-dialog-content>
          <div class="container">
            <div class="item item-1">
              <formly-form
                [model]="model"
                [fields]="fields"
                [options]="options"
                [form]="form"
              ></formly-form>
            </div>

            <div class="item item-2" *ngIf="showDocuments">
              <div style="text-align: center">
                <mat-card class="list-upload">
                  <!--Váriavel que desativa e esconde certos componentes se for para visualizar apenas o modal e não editar os dados do agendamento-->
                  <mat-card-actions *ngIf="data.isEditingAllowed">
                    <button
                      mat-button
                      color="primary"
                      style="text-align: right"
                      (click)="upload()"
                    >
                      {{ "Messages.documentation_upload" | translate:{Default:
                      "Faça aqui o upload da documentação."} }}
                      <mat-icon>backup</mat-icon>
                    </button>
                  </mat-card-actions>
                  <mat-card-content class="list-content">
                    <mat-list>
                      <mat-list-item *ngFor="let item of documents" required>
                        <p class="uploadLabel" matLine>
                          <button
                            *ngIf="data.isEditingAllowed"
                            mat-icon-button
                            color="warn"
                            aria-label="Apagar arquivo"
                            matTooltip="Apagar arquivo"
                            (click)="deleteFile(item)"
                          >
                            <mat-icon>delete_outline</mat-icon>
                          </button>
                          <span
                            matTooltip="Baixar arquivo"
                            class="cursor-pointer"
                            (click)="getFile(item)"
                          >
                            {{ item.fileName }}
                          </span>
                        </p>
                      </mat-list-item>
                    </mat-list>
                  </mat-card-content>
                </mat-card>
                <div *ngIf="!data.event">
                  <input
                    type="file"
                    #fileUpload
                    id="fileUpload"
                    name="fileUpload"
                    multiple="multiple"
                    accept="*"
                    style="display: none"
                    required
                  />
                </div>
              </div>
            </div>
            <div
              class="item item-2"
              *ngIf="showDocumentsByType && documentByTypeData"
            >
              <app-upload-list
                [storedDocuments]="documents"
                [fileTypeList]="fileTypeList"
                [allowedExtensions]="allowed_file_extensions"
                (uploadedDocuments)="uploadedDocuments($event)"
              >
              </app-upload-list>
            </div>
          </div>
        </mat-dialog-content>

        <mat-dialog-actions *ngIf="data.isEditingAllowed" align="end">
          <ng-container *ngIf="loading; else elseTemplate1">
            <button mat-raised-button color="warn" disabled="disabled">
              {{ "Messages.saving" | translate:{Default: 'Salvando ...'} }}
            </button>
          </ng-container>
          <ng-template #elseTemplate1>
            <button type="button" mat-raised-button mat-dialog-close="true">
              {{ "Business_model.close" | translate:{Default: "Fechar"} }}
            </button>
            <button
              [disabled]="!this.form.valid || (this.validationMsgs.length > 0)"
              mat-button
              matStepperNext
              class="greenBtn"
              mat-raised-button
              color="primary"
            >
              {{ "Business_model.next" | translate:{Default: 'Próximo'} }}
            </button>
          </ng-template>
        </mat-dialog-actions>
      </mat-step>
      <mat-step
        [stepControl]="form"
        [label]="'Business_model.notifications' | translate:{Default: 'Notificações'}"
      >
        <div>
          <p *ngIf="data.isEditingAllowed">
            {{ "Messages.emails_notifications" | translate:{ Default: 'Insira
            abaixo os endereços de e-mail que receberão as notificações
            referentes a esse agendamento.'} }}
          </p>
          <p>
            {{ "Messages.when_notification" | translate:{Default: 'Esses e-mails
            serão notificados sempre que:'} }}
          </p>
          <ul>
            <li>
              {{ "Messages.edited_informations" | translate:{Default: 'Houver
              alterações nas informações do formulário;'} }}
            </li>
            <li>
              {{ "Messages.status_change" | translate:{Default: 'Alterações de
              status ocorrerem; ou'} }}
            </li>
            <li>
              {{ "Messages.delete_schedule" | translate:{Default: 'O agendamento
              for excluído'} }}
            </li>
          </ul>
          <br />
          <div>
            <h5 style="margin: 0px 0px 5px 0px">
              <b
                >{{ "Messages.send_messages" | translate:{Default: 'Envio de
                notificações'} }}</b
              >
            </h5>

            <mat-form-field appearance="outline" class="example-chip-list">
              <mat-label *ngIf="data.isEditingAllowed"
                >{{ "Messages.inform_emails" | translate:{Default: 'Informe os
                e-mails'} }}</mat-label
              >
              <mat-chip-grid
                [disabled]="!data.isEditingAllowed"
                #chipGrid
                aria-label="Informe os e-mails"
              >
                <mat-chip-row
                  *ngFor="let email of emails"
                  [removable]="removable"
                  (removed)="remove(email)"
                >
                  {{email}}
                  <button
                    *ngIf="data.isEditingAllowed"
                    matChipRemove
                    [attr.aria-label]="'remove ' + email"
                  >
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
                <input
                  [disabled]="!data.isEditingAllowed"
                  [placeholder]="data.isEditingAllowed ? ('Messages.input_email' | translate:{
                  Default: 'Digite os e-mails que receberão as notificações. Ex: gabriel@trackage.com, marcelo@trackage.com.'}) : ''"
                  [matChipInputFor]="chipGrid"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event)"
                />
              </mat-chip-grid>
              <mat-hint *ngIf="showMsgError == true"
                >{{ "Messages.email_erro" | translate:{ Default: '*Digite um
                e-mail válido. Ex: email@email.com'} }}
              </mat-hint>
            </mat-form-field>

            <mat-dialog-actions *ngIf="data.isEditingAllowed" align="end">
              <ng-container *ngIf="loading; else elseTemplate">
                <button mat-raised-button color="warn" disabled="disabled">
                  {{ "Messages.saving" | translate:{Default: 'Salvando ...'} }}
                </button>
              </ng-container>
              <ng-template #elseTemplate>
                <button type="button" mat-raised-button mat-dialog-close="true">
                  {{ "Business_model.close" | translate:{Default: 'Fechar'} }}
                </button>
                <button
                  class="greenBtn"
                  (click)="storeSchedule(); this.loading = true;"
                  mat-raised-button
                  color="primary"
                  *ngIf="
                    !data.schedule.id && this.config_scheduling.create == true
                  "
                >
                  {{ "Business_model.save" | translate:{Default: 'Salvar'} }}
                </button>

                <button
                  class="greenBtn"
                  (click)="storeSchedule('edit'); this.loading = true;"
                  mat-raised-button
                  color="primary"
                  *ngIf="data.schedule.id && this.config_scheduling.edit == true"
                >
                  {{ "Business_model.save" | translate:{Default: 'Salvar'} }}
                </button>
              </ng-template>
            </mat-dialog-actions>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </form>
</ng-container>
<ng-template #elseTemplateStepper>
  <h2 mat-dialog-title>{{data.dialogAction}}</h2>
  <form [formGroup]="form">
    <mat-dialog-content>
      <div *ngIf="validationMsgs.length > 0" class="alert-denied-list">
        <h4>
          <b
            >{{ "Business_model.attention" | translate:{Default: "Atenção"}
            }}</b
          >
        </h4>
        <p *ngFor="let validate of validationMsgs">{{validate}}</p>
      </div>
      <div
        class="container"
        fxLayout
        fxLayout.xs="column"
        fxLayoutAlign="center"
        fxLayoutGap="20px"
        fxLayoutGap.xs="0"
      >
        <div class="item item-1">
          <formly-form
            [model]="model"
            [fields]="fields"
            [options]="options"
            [form]="form"
          ></formly-form>
        </div>
        <div class="item item-2" *ngIf="showDocuments">
          <div style="text-align: center">
            <mat-card class="list-upload">
              <mat-card-actions *ngIf="data.isEditingAllowed">
                <button
                  mat-button
                  color="primary"
                  style="text-align: right"
                  (click)="upload()"
                >
                  {{ "Messages.documentation_upload" | translate:{Default: "Faça
                  aqui o upload da documentação."} }}
                  <mat-icon>backup</mat-icon>
                </button>
              </mat-card-actions>
              <mat-card-content class="list-content">
                <mat-list>
                  <mat-list-item *ngFor="let item of documents" required>
                    <p
                      matLine
                      matTooltip="Baixar arquivo"
                      class="cursor-pointer"
                    >
                      <span (click)="getFile(item)"> {{ item.fileName }} </span>
                    </p>
                    <button
                      *ngIf="data.isEditingAllowed"
                      mat-icon-button
                      color="warn"
                      matTooltip="Apagar arquivo"
                      aria-label="Apagar arquivo"
                      (click)="deleteFile(item)"
                    >
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </mat-list-item>
                </mat-list>
              </mat-card-content>
            </mat-card>
            <div *ngIf="!data.event">
              <input
                type="file"
                #fileUpload
                id="fileUpload"
                name="fileUpload"
                multiple="multiple"
                accept="*"
                style="display: none"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="data.isEditingAllowed" align="end">
      <ng-container *ngIf="loading; else elseTemplate">
        <button mat-raised-button color="warn" disabled="disabled">
          {{ "Messages.saving" | translate:{Default: 'Salvando ...'} }}
        </button>
      </ng-container>
      <ng-template #elseTemplate>
        <button type="button" mat-raised-button mat-dialog-close="true">
          {{ "Business_model.close" | translate:{Default: 'Fechar'} }}
        </button>
        <button
          class="greenBtn"
          (click)="storeSchedule()"
          mat-raised-button
          color="primary"
          *ngIf="!data.schedule.id && this.config_scheduling.create == true"
        >
          {{ "Business_model.save" | translate:{Default: 'Salvar'} }}
        </button>
        <button
          class="greenBtn"
          (click)="storeSchedule('edit')"
          mat-raised-button
          color="primary"
          *ngIf="data.schedule.id && this.config_scheduling.edit == true"
        >
          {{ "Business_model.save" | translate:{Default: 'Salvar'} }}
        </button>
      </ng-template>
    </mat-dialog-actions>
  </form>
</ng-template>
