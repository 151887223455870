<div class="container">


  <!-- <mat-card class="driver-panel-card">
      <mat-card-title>Bloqueio de agenda</mat-card-title>
    <mat-card-content>
      <table class="tkg-table">
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Bloqueio<br /></th>
            <th>Recorrência<br /></th>
            <th>Ação<br /></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let blk of blocks">
            <td>{{showType(blk.resource)}}</td>
            <td>{{showBlock(blk.resource)}}</td>
            <td>{{showRecorrency(blk.resource)}}</td>
            <td>
              <button mat-icon-button color="warn" (click)="removeBlock(blk.id)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </td>
          </tr>

        </tbody>
      </table>
    </mat-card-content>
    <mat-card-actions>
      <button mat-stroked-button color="primary" (click)="addBlock()">ADICIONAR</button>

    </mat-card-actions>
  </mat-card> -->
  <app-schedule-window [hiddenElements]="account.system_client.resource.environment.hidden_elements?.configurations?.scheduling" *ngIf="this.config_scheduling?.shedule_windows"></app-schedule-window>
  <br>
  <app-schedule-blocks [hiddenElements]="account.system_client.resource.environment.hidden_elements?.configurations?.scheduling" *ngIf="this.config_scheduling?.shedule_blocks"></app-schedule-blocks>
  <br>
  <app-schedule-limits *ngIf="this.config_scheduling?.shedule_limits"></app-schedule-limits>
  <br>
  <app-schedule-antecedence [hiddenElements]="account.system_client.resource.environment.hidden_elements?.configurations?.scheduling" *ngIf="this.config_scheduling?.shedule_antecedence"></app-schedule-antecedence>
  <br>
  <app-schedule-status [hiddenElements]="account.system_client.resource.environment.hidden_elements?.configurations?.scheduling" *ngIf="this.config_scheduling?.shedule_status"></app-schedule-status>
  <br>
  <app-pre-schedule-status [hiddenElements]="account.system_client.resource.environment.hidden_elements?.configurations?.scheduling" *ngIf="this.config_scheduling?.shedule_status"></app-pre-schedule-status>
  <app-pre-schedule-document [hiddenElements]="account.system_client.resource.environment.hidden_elements?.configurations?.scheduling" *ngIf="this.config_scheduling?.shedule_status"></app-pre-schedule-document>
  <br>
  </div>
