import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { format } from "date-fns";
import { BehaviorSubject, Subscription } from "rxjs";
import { AccountService } from "src/app/account/shared/account.service";
import { AgendamentoService } from "src/app/multiagendamento/shared/agendamento.service";
import { ReportListDialogFiltersComponent } from "src/app/report-dynamic/report-list-dialog-filters/report-list-dialog-filters.component";
import { ReportDynamicService } from "src/app/report-dynamic/shared/report-dynamic.service";
import { CommentsComponent } from "src/app/shared/comments/comments.component";
import { DialogAlertComponent } from "src/app/shared/dialog-alert/dialog-alert.component";
import { DialogConfirmComponent } from "src/app/shared/dialog-confirm/dialog-confirm.component";
import { GenericService } from "src/app/shared/generic.service";
import { SetPreScheduleDateTimeComponent } from "src/app/shared/set-pre-schedule-date-time/set-pre-schedule-date-time.component";
import { StepsFilterService } from "src/app/shared/steps-filter.service";
import { SupportDataService } from "src/app/shared/support-data.service";
import { WSService2 } from "src/app/shared/websocket/wss2.service";
import { ScheduleDinamicFormDialogV1Component } from "../schedule-dinamic-form-dialog-v1/schedule-dinamic-form-dialog-v1.component";
import { ScheduleEditDialogV1Component } from "../schedule-edit-dialog-v1/schedule-edit-dialog-v1.component";

@Component({
  selector: "app-pre-schedule-list",
  templateUrl: "./pre-schedule-list.component.html",
  styleUrls: ["./pre-schedule-list.component.scss"],
})
export class PreScheduleListComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  pageParam: string;
  searchWatcher: Subscription;
  search: string = "";
  page: number = 1;
  pageSize: number = 10;
  orderBy: string;
  sortedBy: string;
  isEditingAllowed = true;
  showListEmpty = false;
  showLoadingData = true;
  showMessage = false;
  message: string;
  form = this.formBuilder.group({
    day: [format(new Date(), "yyyy-MM-dd"), [Validators.required]],
    time: null,
    search: null,
    department_id: [],
    origin: [],
    operation_id: null,
    suboperation_id: null,
    status_id: [],
    offset: this.getTimeZone(),
  });
  schedule: any;
  configScheduling: any;
  waitingResponse = false;
  selectedDate = new Date();
  selectedDay!: string;
  dataSource = new MatTableDataSource<any>();
  totalItems: number;
  displayedColumns: string[];
  columnsSchema: any;
  searchFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  paginate = true;
  appliedFilters: any;
  adjustedFilters: any;
  filterWatcher: any;
  actions_list: any = [];
  open: BehaviorSubject<boolean> = new BehaviorSubject(false);
  account: any;
  constructor(
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private stepsFilterService: StepsFilterService,
    private genericService: GenericService,
    private translate: TranslateService,
    private supportDataService: SupportDataService,
    private agendamentoService: AgendamentoService,
    public dialogRef: MatDialogRef<ScheduleEditDialogV1Component>,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private wssService: WSService2,
  ) {
    this.account = JSON.parse(localStorage.getItem("account")!);
    this.onSelect(this.selectedDate);
  }

  ngOnInit() {
    document.addEventListener(
      "visibilitychange",
      () => {
        if (document.visibilityState === "visible") {
          if (this.wssService.socket$.closed.valueOf()) {
            this.wssService.closeConnection();
          } else {
            this.subscribeWebsocket();
          }
        }
      },
      false,
    );
    this.getActionList();
    this.getData();

    this.filterWatcher = this.stepsFilterService.show.subscribe(
      (filter: any) => {
        this.showDialogFilters();
      },
    );

    const account = JSON.parse(localStorage.getItem("account") || "{}");
    this.configScheduling =
      account.system_client.resource.environment.config_scheduling;
  }

  ngAfterViewInit(): void {
    this.subscribeWebsocket();
  }

  ngOnDestroy(): void {
    if (!this.wssService.socket$.closed.valueOf()) {
      this.wssService.closeConnection();
    }
  }

  subscribeWebsocket() {
    console.log("Subscribe WebSocket");
    let id = JSON.parse(localStorage.getItem("account")!).system_client.id;
    this.wssService.CHANNEL = `/pre_schedule/${id}`;
    this.wssService.connect().subscribe((msg: any) => {
      const wsMsg: any = Object.assign({}, msg);
      if (wsMsg.hasOwnProperty("action")) {
        if (wsMsg.action === 2) {
          // Update -> Define mensagem de update
          if (wsMsg.user_id != this.account.id) {
            if (wsMsg.msg) {
              this.snackBarMessage(wsMsg.msg);
            } else {
              this.snackBarMessage("Pré-agendamento atualizado");
            }
          }
        } else {
          // Após implementação na API do app, remover este trecho (else) e descomentar o trecho acima
          if (wsMsg.msg) {
            this.snackBarMessage(wsMsg.msg);
          } else {
            this.snackBarMessage("Novo pré-agendamento realizado");
          }
        }
      }
      this.getData();
    });
  }

  snackBarMessage(msg: string) {
    this.snackBar.open(msg, "OK", {
      duration: 3000,
      horizontalPosition: "left",
      verticalPosition: "bottom",
    });
  }

  getActionList() {
    this.supportDataService
      .generic({ name: "view-pre_schedule_actions" })
      .subscribe((res: any) => {
        // this.actions_list = this.dynamicActionsService.actionList(res.data.resource);
        const obj: any = res.data.resource;
        this.accountService.identity().then((identity: any) => {
          const user = identity;
          if (identity.hasOwnProperty("resource")) {
            if (identity.resource.hasOwnProperty("is_partner")) {
              const is_partner = identity.resource.is_partner;
              console.log("is_partner");
              console.log(is_partner);
              if (is_partner) {
                if (obj.hasOwnProperty("differ_by")) {
                  const bpTypeKey = obj.differ_by;
                  const bpTypeId = user.resource[bpTypeKey];
                  this.actions_list =
                    obj[bpTypeId.toString() as keyof typeof obj];
                }
              } else {
                this.actions_list = obj.default;
              }
            }
          }
        });
      });
  }

  action(action: any) {
    console.log(action);
    if (action[0] === "delete") {
      this.deletePreSchedule(action[1].id, action[1].status);
    }
    if (action[0] === "edit") {
      this.editPreSchedule(action[1], true);
    }
    if (action[0] === "view_pre_schedule") {
      this.editPreSchedule(action[1], false);
    }
    if (action[0] === "aprove_pre_schedule") {
      this.aprovePreSchedule(action[1]);
    }
    if (action[0] === "reprove_pre_schedule") {
      this.reprovePreSchedule(action[1]);
    }
    if (action[0] === "set_date_time") {
      console.log(action);
      this.setDateTime(action[1]);
    }
    if (action[0] === "request_analysis") {
      console.log(action);
      this.requestAnalysis(action[1]);
    }
  }

  setDateTime(action: any) {
    this.open = new BehaviorSubject(false);
    this.dialog
      .open(SetPreScheduleDateTimeComponent, {
        panelClass: ["dialog-small"],
        disableClose: true,
        data: {
          title: "Definir Data/Hora",
          department_id: action.department_id,
          operation_id: action.operation_id,
          suboperation_id: action.suboperation_id,
          vehicle_type: action.vehicle_type,
        },
      })
      .afterClosed()
      .subscribe((dialogResult?: any) => {
        if (dialogResult.save) {
          this.agendamentoService
            .alterarStatusMv({
              ...action,
              ...dialogResult,
              status: 10,
              schedule_type: "schedule",
            })
            .subscribe((r: any) => {
              this.getData();
            });
        }
      });
  }

  aprovePreSchedule(action: any) {
    const dialogRef = this.dialog.open(CommentsComponent, {
      panelClass: ["dialog-medium"],
      data: {
        title: "Aprovar pré-agendamento",
        message: "Tem certeza que deseja aprovar o pré-agendamento?",
        item: action,
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.waitingResponse = true;
        this.agendamentoService
          .alterarStatusMv({
            ...action,
            status: 2,
            schedule_type: "pre_schedule",
            observacoes: confirm.observacoes,
          })
          .subscribe((r: any) => {
            console.log(r);
            this.getData();
          });
      }
    });
  }

  reprovePreSchedule(action: any) {
    const dialogRef = this.dialog.open(CommentsComponent, {
      panelClass: ["dialog-medium"],
      data: {
        title: "Reprovar pré-agendamento",
        message: "Tem certeza que deseja reprovar o pré-agendamento?",
        item: action,
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.waitingResponse = true;
        this.agendamentoService
          .alterarStatusMv({
            ...action,
            status: 4,
            observacoes: confirm.observacoes,
          })
          .subscribe((r: any) => {
            console.log(r);
            this.getData();
          });
      }
    });
  }

  requestAnalysis(actions: any) {
    console.log("actions");
    console.log(actions);
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirmar Ação!",
        message: "Tem certeza que deseja 'Solicitar Análise'?",
        btnConfirm: "Sim",
        btnCancel: "Não",
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        console.log("if");
        const options = {
          id: actions.id,
          status: 2,
        };
        this.agendamentoService.alterarStatusMv(options).subscribe((r: any) => {
          console.log("salvou");
          console.log(r);
          this.getData();
        });
      } else {
        console.log("else");
        this.getData();
      }
    });
  }

  getData() {
    const options = {
      start: this.formatDateDB(new Date()),
      end: this.formatDateDB(new Date()),
      search: this.search,
      paginate: true,
      pageSize: this.pageSize || 10,
      page: this.page || 1,
      orderBy: this.orderBy || "",
      sortedBy: this.sortedBy || "",
      ...this.adjustedFilters,
    };

    this.genericService.getData("pre_schedule", options).subscribe((r: any) => {
      console.log("buscou");
      this.showLoadingData = this.dataSource.data.length === 0;
      this.dataSource.data = r.data;
      if (!this.columnsSchema) {
        console.log("Define schema pela primeira vez");
        this.columnsSchema = r.schema;
        this.displayedColumns = this.columnsSchema.map((col: any) => col.key);
        this.totalItems = r.total;
        this.showLoadingData = false;
        this.showListEmpty = r.data.length === 0 ? true : false;
        this.paginate = r.data.length > 0;
      } else {
        console.log("schema já definido");
        this.totalItems = r.total;
        this.showLoadingData = false;
        this.showListEmpty = r.data.length === 0 ? true : false;
        this.paginate = r.data.length > 0;
      }
    });
  }

  formatDateDB(date: any) {
    const dt = new Date(date);
    const year = dt.toLocaleString("default", { year: "numeric" });
    const month = dt.toLocaleString("default", {
      month: "2-digit",
    });
    const day = dt.toLocaleString("default", { day: "2-digit" });

    return [year, month, day].join("-");
  }

  // clearFilter(event?:any) {
  //   if (this.searchFilter.value) {
  //     this.searchFilter.setValue("");
  //   }
  // }

  performOnChangePage(event: any) {
    console.log("pageChange");
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getData();
  }

  performSortChange(event: any) {
    console.log("sortChange");
    this.orderBy = event.active;
    this.sortedBy = event.direction;
    this.getData();
  }

  showDialogFilters() {
    const dialogRef = this.dialog.open(ReportListDialogFiltersComponent, {
      panelClass: ["dialog-medium"],
      data: { page: this.pageParam, fts: this.appliedFilters },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Aplica os filtros
        this.appliedFilters = result;
        // Conta a quantidade de filtros e publica no serviço
        this.countNumberOfFilters(this.appliedFilters);
        // Busca os dados com os filtros aplicados

        this.adjustFilters();
      }
    });
  }

  countNumberOfFilters(filters: any) {
    const filtersKeys = Object.keys(this.appliedFilters);
    let qtdFts: any[] = [];
    filtersKeys.forEach((key: string) => {
      if (this.appliedFilters[key]) {
        if (Array.isArray(this.appliedFilters[key])) {
          if (this.appliedFilters[key].length > 0) {
            qtdFts.push(1);
          }
        } else if (this.appliedFilters[key] != null) {
          qtdFts.push(1);
        }
      }
    });
    this.stepsFilterService.onChange(qtdFts.length.toString(), "");
  }

  adjustFilters() {
    let filter: any = {};
    let localFilters = JSON.parse(JSON.stringify(this.appliedFilters));
    localFilters.forEach((item: any) => {
      const op = item.op;
      delete item.filter_name;
      delete item.value_name;
      delete item.op;
      // Conta a quantidade de chaves do objeto e formata cada uma
      Object.keys(item).forEach((i: any) => {
        let key_op = `${i}${op}`;
        filter[key_op] = item[i];
      });
    });
    this.adjustedFilters = filter;
    //this.getReportData();
  }

  getTimeZone() {
    let offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    return (
      (offset < 0 ? "+" : "-") +
      ("00" + Math.floor(o / 60)).slice(-2) +
      ":" +
      ("00" + (o % 60)).slice(-2)
    );
  }

  onSelect(event: any) {
    this.selectedDate = event;
    this.selectedDay = `${format(new Date(event), "yyyy-MM-dd")}T00:00:00`;
    this.form.patchValue({ day: format(new Date(event), "yyyy-MM-dd") });
  }

  new_pre_scheduling() {
    this.open = new BehaviorSubject(false);
    const dialogRef = this.dialog.open(ScheduleDinamicFormDialogV1Component, {
      // width: "900px",
      panelClass: ["dialog-large"],
      data: {
        dialogAction: this.translate.instant("Business_model.new_schedule", {
          Default: "Novo Pré Agendamento",
        }),
        slot: null,
        schedule: {
          id: null,
          selected_day: null,
          status: 1,
          schedule_type: "pre_schedule",
          showStepTwo: false,
        },
        scheduleOrigin: "pre_scheduling_dialog_fields",
        //Criar e editar utilizam o mesmo modal, então para a criação 'isEditingAllowed' é marcado como true para que todos os campos estejam habilitados
        isEditingAllowed: true,
      },
    });

    dialogRef.afterClosed().subscribe((statusResult?: any) => {
      //Se status for new_code_generated, abrir modal informando o usuário
      //Que o agendamento foi criado com um código diferente do informado
      if (statusResult && statusResult.status == "new_code_generated") {
        this.dialog
          .open(DialogAlertComponent, {
            data: {
              title: this.translate.instant("Business_model.created_schedule", {
                Default: "Agendamento criado",
              }),
              message:
                statusResult.message ||
                this.translate.instant("Messages.created_schedule", {
                  Default: "Agendamento criado, código alterado.",
                }),
            },
          })
          .afterClosed()
          .subscribe(() => {
            console.log("ao adicionar um pre agendamento");
            this.getData();
          });
      } else {
        this.getData();
      }
    });
  }

  editPreSchedule(preScheduleData: any, edit: any) {
    const isEditingAllowed = edit || true;
    this.open = new BehaviorSubject(false);
    this.dialog
      .open(ScheduleDinamicFormDialogV1Component, {
        width: "100%",
        height: "100%",
        data: {
          dialogAction: isEditingAllowed
            ? this.translate.instant("Business_model.edit_schedule", {
                Default: "Editar Pré Agendamento",
              })
            : this.translate.instant("Business_model.view_schedule", {
                Default: "Visualizar Pré Agendamento",
              }),
          slot: {},
          scheduleOrigin: "pre_scheduling_dialog_fields",
          schedule: { ...preScheduleData, schedule_type: "pre_schedule" },
          isEditingAllowed: true,
        },
      })
      .afterClosed()
      .subscribe((editResult?: any) => {
        this.getData();
      });
  }

  deletePreSchedule(id: number, status: string) {
    if (id && status != "denied" && this.configScheduling.delete == true) {
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          title: "Confirma a exclusão?",
          message: "Tem certeza que deseja 'Excluir o pré agendamento'?",
        },
      });

      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.waitingResponse = true;
          this.agendamentoService
            .dinamicScheduleDeleteV1(id)
            .subscribe((r: any) => {
              this.waitingResponse = false;
              const message = r.error
                ? r.msg ||
                  "Ocorreu um error inesperado durante o processo de exclusão do agendamento."
                : "Agendamento excluído com sucesso!";

              this.info("Agendamento Exclusão", message)
                .afterClosed()
                .subscribe(() => {
                  this.getData();
                });
            });
        }
      });
    } else {
      this.info(
        "Agendamento Exclusão",
        "A configuração de agendamentos não permite a exclusão.",
      );
    }
  }

  private info(
    title: string,
    message: string,
  ): MatDialogRef<DialogAlertComponent> {
    return this.dialog.open(DialogAlertComponent, {
      data: { title, message },
    });
  }
}
