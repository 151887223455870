import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith } from "rxjs/operators";
import { DriversService } from 'src/app/drivers/shared/drivers.service';

@Component({
  selector: 'formly-ss-driver',
  templateUrl: './formly-ss-driver.component.html',
  styleUrls: ['./formly-ss-driver.component.scss'],

})
export class FormlySsDriverComponent extends FieldType {

  constructor(private driverService: DriversService) {
    super();
  }

  _formControl = new FormControl();
  filter:Observable<any>;
  ngModule:any;
  selectedItem = false;

  ngOnInit(){

    this._formControl.valueChanges.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          startWith(''),
          map(value => value)
          ).subscribe(value => {
            if(typeof value === 'string') {
              this.filter = this.driverService.getDrivers({search: value});
            }
          });

  }

  clear(){
    this.ngModule = "";
    this.formControl.setValue("");
  }

  displaySelected(opt: any) {
    if(opt){
      this.formControl.setValue(opt.id);
      return opt && opt.name ? opt.name : '';
    }
  }
}
