import { format } from "date-fns";
import { forkJoin, finalize, Observable, of } from "rxjs";
import { COMMA, ENTER, P } from "@angular/cdk/keycodes";
import { TranslateService } from "@ngx-translate/core";
import { MatChipInputEvent } from "@angular/material/chips";
import { map, catchError, delay, filter } from "rxjs/operators";
import { FormlyFormOptions, FormlyFieldConfig } from "@ngx-formly/core";
import { HttpEventType, HttpErrorResponse } from "@angular/common/http";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from "@angular/forms";

import { PlanningService } from "./../../shared/planning.service";
import { DriversService } from "src/app/drivers/shared/drivers.service";
import { CarrierService } from "src/app/carrier/shared/carrier.service";
import { SupportDataService } from "src/app/shared/support-data.service";
import { DocumentsService } from "src/app/documents/shared/documents.service";
import { AgendamentoService } from "src/app/multiagendamento/shared/agendamento.service";
import { DialogConfirmComponent } from "src/app/shared/dialog-confirm/dialog-confirm.component";
import { DialogAlertComponent } from "src/app/shared/dialog-alert/dialog-alert.component";

@Component({
  selector: "app-schedule-dinamic-form-dialog-v1",
  templateUrl: "./schedule-dinamic-form-dialog-v1.component.html",
  styleUrls: ["./schedule-dinamic-form-dialog-v1.component.scss"],
})
export class ScheduleDinamicFormDialogV1Component implements OnInit {
  @ViewChild("fileUpload") fileUpload: ElementRef;

  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  statusBoxLostFocus = false;
  files: {
    data: File;
    inProgress: boolean;
    progress: number;
    uploaded: boolean;
  }[] = [];
  documents: any = [];
  fileTypeList: any[] = [];
  loading = false;
  offset: string;
  form = new UntypedFormGroup({
    emails: new UntypedFormControl([]),
  });
  model: any = {};
  options: FormlyFormOptions = {};
  validationMsgs: any[] = [];
  showForm = false;
  allSuboperations: any[];
  allOperations: any[];
  suboperationsFt: any[];
  operationsFt: any[];
  fields: FormlyFieldConfig[] = [];
  aditionalFields: any = {};
  dataVehicleType: any[];
  account: any;
  use_department: any;
  scheduleActions: any;
  config_scheduling: any;
  showDocuments: boolean = false;
  showDocumentsByType: boolean = false;
  documentByTypeData: boolean = false;
  scheduleStatusList: any = [];
  onlyPdf: boolean = false;
  scheduleStatus = 1;
  statusName = "";
  statusColor = "";
  changeStatusBox = false;
  tagArray = [];
  tagInput: any;
  //Pattern em regex que verifica se a string possui colchetes
  hasBrackets = /[[\]]/m;
  //Pattern em regex que verifica se a string não possui caracteres especiais (Ex: "',.!;:?/!@#$%'()[]{}")
  validMail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  //Ativa a mensagem de erro em <mat-hint>
  //<mat-error> depende de formControl para que funcione em um input, então nesse caso é utilizado <mat-hint> para mostrar a mensagem de erro
  showMsgError = false;
  schedule_type = "";
  showStepTwo = true;

  formControl: any;
  visible = true;
  removable = true;
  addOnBlur = true;
  isLinear = true;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  emails: string[] = [];
  allowed_file_extensions: string;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    public driverService: DriversService,
    public carrierService: CarrierService,
    private formBuilder: UntypedFormBuilder,
    private planningService: PlanningService,
    public documentsService: DocumentsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public supportDataService: SupportDataService,
    public agendamentoService: AgendamentoService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ScheduleDinamicFormDialogV1Component>,
  ) {
    this.schedule_type = this.data.schedule.hasOwnProperty("schedule_type")
      ? this.data.schedule.schedule_type
      : "schedule";
  }

  ngOnInit() {
    console.log("++++++++ FORM AGENDAMENTO +++++++++");
    console.log(this.data);
    if (this.data.schedule.hasOwnProperty("showStepTwo")) {
      this.showStepTwo = this.data.schedule.showStepTwo;
    }
    this.account = JSON.parse(localStorage.getItem("account") || "{}");
    this.allowed_file_extensions =
      this.account.system_client.resource.environment
        .upload_list_allowed_file_extensions || null;
    this.use_department =
      this.account?.system_client?.resource?.environment?.use_department;
    this.config_scheduling =
      this.account?.system_client?.resource?.environment?.config_scheduling;

    this.offset = this.getTimeZone();
    forkJoin([
      this.getScheduleFields(),
      this.getDepartaments(),
      this.getOperations(),
      this.getSuboperations(),
      this.getCarriers(),
      this.getVehicleType(),
      this.getPlanning(),
    ]).subscribe((result) => {
      // Schedule Field process
      this.processScheduleFields(result[0]);
      this.processOperations(result[2]);
      this.processDepartaments(result[1]);
      this.processSuboperations(result[3]);
      this.processCarriers(result[4]);
      this.processVehicleType(result[5]);
      this.processPlanning(result[6]);

      const documentsResource = result[0].data.resource.find(
        (item: any) => item.key === "documents",
      );
      if (documentsResource.props.onlyPdf === true) {
        this.onlyPdf = true;
      }

      this.setDataIntoForm();
      //this.form.valueChanges.subscribe(() => {})
      this.data.schedule &&
        this.data.schedule.id &&
        (this.scheduleStatus = this.data.schedule.status);

      of(this.data.schedule)
        .pipe(delay(500))
        .pipe(
          filter((schedule) => this.form.get("schedule_date") && schedule.id),
        )
        .pipe(map(() => this.form.get("schedule_date")))
        .pipe(
          finalize(() => {
            //Devido a natureza de requsições em angular serem assíncronas, form.disable só funciona corretamente quando chamado dentro de um subscribe
            //Então é colocado dentro de finalize() que é executado no momento que o subscribe for finalizado
            if (!this.data.isEditingAllowed) {
              this.form.disable();
            }
          }),
        )
        .subscribe((schedule_date) => {
          schedule_date?.setValue(
            new Date(
              this.data.schedule.selected_day ?? this.data.schedule.start_at,
            ),
          );
        });
    });
    this.getScheduleStatus();
    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ["", Validators.required],
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ["", Validators.required],
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  getPlanning() {
    return this.planningService.index({ pageSize: 1000 });
  }

  processPlanning(r: any) {
    let idx = this.fields.map((o) => o.key).indexOf("planning_id");
    if (idx > -1) {
      this.fields[idx].props!.options = r.data;
    }
  }

  getScheduleFields(): Observable<any> {
    let name_schedule = "scheduling_dialog_fields";
    if (this.data.hasOwnProperty("scheduleOrigin")) {
      name_schedule = this.data.scheduleOrigin;
    }
    return this.supportDataService.generic({ name: name_schedule });
  }

  getScheduleStatus() {
    let statusListName: string = "";
    if (this.schedule_type == "schedule") {
      statusListName = "scheduling_status";
    }
    if (this.schedule_type == "pre_schedule") {
      statusListName = "pre_schedule_status";
    }
    this.supportDataService
      .generic({ name: statusListName })
      .subscribe((r: any) => {
        this.scheduleStatusList = r.data.resource;
        this.changeStatusNameColor();
      });
  }

  setStatus(st: any) {
    this.scheduleStatus = st;
    this.changeStatusNameColor(st);
    this.showChangeStatusBox();
  }

  changeStatusNameColor(id?: any) {
    let status_id = id || parseInt(this.data.schedule.status);
    let ft = this.scheduleStatusList.filter((i: any) => i.id === status_id);
    this.statusName = ft[0].name;
    this.statusColor = ft[0].color;
  }

  hideChangeStatusBox() {
    if (this.changeStatusBox) {
      this.statusBoxLostFocus = !(this.changeStatusBox = false);
      of(false)
        .pipe(delay(300))
        .subscribe((x) => (this.statusBoxLostFocus = x));
    }
  }

  showChangeStatusBox() {
    if (this.statusBoxLostFocus) return false;

    return (this.changeStatusBox = !this.changeStatusBox);
  }

  processScheduleFields(r: any) {
    this.fields = r.data.resource.reduce((fields: any, field: any) => {
      if (field.type === "checkbox") {
        field["defaultValue"] = false;
      }

      if (field.key == "schedule_date") {
        if (this.data.schedule.selected_day) {
          const start_at = new Date(
            new Date(
              this.data.schedule.selected_day ?? this.data.schedule.start_at,
            ).setHours(0, 0, 0, 0),
          );
          const now = new Date(new Date().setHours(0, 0, 0, 0));
          field.defaultValue = start_at < now ? now : start_at;
          if (!field.props.datepickerOptions)
            field.props.datepickerOptions = {};

          field.props.datepickerOptions.min = now;
        }
      }

      //Condicionais para esconder os campos de type "formly-schedule-code" e "formly-input-tags-outline"
      //Devido a natureza de seus componentes com partes fora de um mat-form-field o parametro boolean 'isEditingAllowed'
      //É passado dentro de suas props que então é utilizado para desativar partes do componente
      if (field.type === "formly-schedule-code") {
        field.props.isEditingAllowed = this.data.isEditingAllowed;
      }

      if (
        field.type === "formly-input-tags-outline" &&
        field.props.disabled === false
      ) {
        field.props.isEditingAllowed = this.data.isEditingAllowed;
      }

      if (
        field.key === "carrier_id" &&
        (field.type === "formly-input-search" ||
          field.type === "formly-input-search-cnpj")
      ) {
        field.props!.service = {
          startWith: this.data.carrier_name || "",
          debounceTime: 300,
          service: (search: string) => {
            const options = {
              orderBy: "name",
              sortedBy: "ASC",
              page: 1,
              pageSize: 1000,
              search,
            };
            return this.carrierService.index(options).pipe(
              map((result: any) => result?.data || []),
              catchError(() => of({ data: [] })),
            );
          },
        };
      }

      if (field.key === "cpf" && field.type === "formly-input-search-driver") {
        field.props!.service = {
          startWith: this.data.driver_name || "",
          debounceTime: 300,
          service: (search: string) => {
            const options = {
              orderBy: "name",
              sortedBy: "ASC",
              page: 1,
              pageSize: 1000,
              search,
            };
            return this.driverService.getDrivers(options).pipe(
              map((result: any) => result || []),
              catchError(() => of({ data: [] })),
            );
          },
        };
      }

      return [...fields, field];
    }, []);

    let idx = this.fields.map((o) => o.key).indexOf("cpf");
    if (idx > -1) {
      this.setCpfFieldOptions();
    }

    idx = this.fields.map((o) => o.key).indexOf("cnpj");
    if (idx > -1) {
      this.setCnpjFieldOptions();
    }

    idx = this.fields.map((o) => o.key).indexOf("documents");
    if (idx > -1) {
      this.showDocuments = true;
    }

    idx = this.fields.map((o) => o.key).indexOf("documents_by_type");
    if (idx > -1) {
      this.showDocumentsByType = true;
    }

    // ToDo: Processa selects de fontes diversas
    this.fields.forEach((item: any, idx: number) => {
      if (item.hasOwnProperty("select_from")) {
        // Implementa busca no support data
        if (item.select_from === "support_data") {
          this.supportDataService
            .generic({ name: item.support_data_name })
            .subscribe((r: any) => {
              this.fields[idx].props!.options = r.data.resource;
            });
        }
      }
    });
  }

  getDepartaments(): Observable<any> {
    return this.supportDataService.generic({ name: "departments" });
  }

  processDepartaments(r: any) {
    // Verifica se existe apenas 1 departamento e atribui o valor padrão.
    // Caso exista mais de um, mostra a listagem

    //Pega o index do campo de operações em this.fields
    const operationIndex = this.fields
      .map((o) => o.key)
      .indexOf("operation_id");
    const idx = this.fields.map((o) => o.key).indexOf("department_id");
    let departments = r.data.resource;
    this.fields[idx].props!.options = departments;
    if (departments.length === 1) {
      this.fields[idx].defaultValue = departments[0].id;
      this.setOperations(this.fields[idx].defaultValue);
      this.fields[idx].className = "display-none";
    } else if (!this.data.schedule.id) {
      //Se não possuir data.schedule.id, é um agendamento novo para um cliente
      //E também possui mais de um departamento, então desativa o campo de Operações
      //Até que seja informado um departamento
      this.fields[operationIndex].props!.disabled = true;
    }
    this.fields[idx].props!.change = (field, $event) => {
      //Ao houver mudança e for informado um departamento, reativa o campo de Operações
      this.fields[operationIndex].props!.disabled = false;
      this.setOperations(field.formControl!.value);
    };
  }

  getOperations(): Observable<any> {
    return this.supportDataService.generic({ name: "operation_type" });
  }

  processOperations(r: any) {
    this.allOperations = r.data.resource;
    const idx = this.fields.map((o) => o.key).indexOf("operation_id");
    if (idx > -1) {
      this.fields[idx].props!.options = this.allOperations;
      this.fields[idx].props!.change = (field, $event) => {
        if (this.fields.map((o) => o.key).indexOf("suboperation_id") > -1) {
          this.setSuboperations(field.formControl!.value);
        }

        if (this.schedule_type == "schedule") {
          this.getTimeSlots(
            this.form.value.department_id,
            this.form.value.operation_id,
            this.form.value.suboperation_id,
          );
        }
        this.getDocumentsUploadRules(this.form.value.operation_id, null);
      };
    }
  }

  getDocumentsUploadRules(operation_id: number, suboperation_id: any) {
    this.documentByTypeData = false;
    this.documentsService
      .indexDocumentUpload({
        operation_id: operation_id,
        suboperation_id: suboperation_id || null,
      })
      .subscribe((r: any) => {
        if (r.data.length > 0) {
          //
          this.fileTypeList = JSON.parse(r.data[0].upload_document_type);
          this.fileTypeList.filter(() =>
            r.data[0].groups.includes(this.account.group_id),
          );

          this.documentByTypeData = true;
        }
      });
  }

  getSuboperations(): Observable<any> {
    return this.supportDataService.generic({ name: "suboperation_type" });
  }

  processSuboperations(r: any) {
    // Adiciona evento change para o select
    this.allSuboperations = r.data.resource;
    const idx = this.fields.map((o) => o.key).indexOf("suboperation_id");
    if (idx > -1) {
      this.fields[idx].props!.change = () => {
        if (this.schedule_type == "schedule") {
          this.getTimeSlots(
            this.form.value.department_id,
            this.form.value.operation_id,
            this.form.value.suboperation_id,
          );
        }
        this.getDocumentsUploadRules(
          this.form.value.operation_id,
          this.form.value.suboperation_id,
        );
      };
    }
  }

  getVehicleType(): Observable<any> {
    return this.supportDataService.generic({ name: "vehicle_type" });
  }

  showBoard(field: any) {
    let qtdPlacas = 0;
    this.dataVehicleType.filter((item: any) => {
      if (item.id == field) {
        qtdPlacas = item.plate_amount;
      }
    });

    let idx = 0;

    // ocultar as placas novamente
    this.fields.forEach((item: any) => {
      if (item.key.substring(0, 5) == "board") {
        this.fields[this.fields.map((o) => o.key).indexOf(item.key)].hide =
          true;
      }
    });

    if (qtdPlacas == 1) {
      if (this.account.system_client.resource.environment.trip_hitch) {
        idx = this.fields.map((o) => o.key).indexOf("board_cart");
        this.fields[idx].hide = false;
      } else {
        idx = this.fields.map((o) => o.key).indexOf("board_horse");
        if (idx > 0) {
          this.fields[idx].hide = false;
        }
      }
    }

    if (qtdPlacas >= 2) {
      idx = this.fields.map((o) => o.key).indexOf("board_horse");
      if (idx > 0) {
        this.fields[idx].hide = false;
      }

      idx = this.fields.map((o) => o.key).indexOf("board_cart");
      if (idx > 0) {
        this.fields[idx].hide = false;
      }

      if (qtdPlacas > 2) {
        for (let x = 2; x < qtdPlacas; x++) {
          idx = this.fields.map((o) => o.key).indexOf("board_cart" + x);
          if (idx > 0) {
            this.fields[idx].hide = false;
          }
        }
      }
    }
  }

  processVehicleType(r: any) {
    const idx = this.fields.map((o) => o.key).indexOf("vehicle_type");
    if (idx > -1) {
      this.fields[idx].props!.options = r.data.resource;
      this.dataVehicleType = r.data.resource;
      this.fields[idx].props!.change = (field) => {
        this.showBoard(field.formControl!.value);
      };
    }
  }

  getCarriers(): Observable<any> {
    return this.carrierService.index({ pageSize: 1000 });
  }

  processCarriers(r: any) {
    let idx = this.fields.map((o) => o.key).indexOf("carrier_id");
    if (idx > -1) {
      this.fields[idx].props!.options = r.data.map((carrier: any) => {
        if (carrier.cnpj) {
          carrier.name = carrier.name + " - " + carrier.cnpj;
        }
        return carrier;
      });

      //Verifica se é usuário de transportadora e se possui apenas uma transportadora cadastrada
      //Se sim passa o id de indice 0 do array carriers do usuário como valor padrão e desabilita o input do campo
      if (this.account.is_carrier && this.account.carriers.length === 1) {
        this.fields[idx].props!.disabled = true;
        this.fields[idx].defaultValue = this.account.carriers[0];
      }
    }
  }

  // Busca os slots
  getTimeSlots(
    department_id: number,
    operation_id: number,
    suboperation_id: number,
    vehicle_type?: any,
  ) {
    // if (!this.data.schedule.id) {
    //   this.data.schedule.selected_day = format(new Date(), "yyyy-MM-dd");
    // }

    // if (this.data?.schedule?.selected_day || this.form?.value?.schedule_date) {
    // console.log(this.data.schedule.selected_day);
    const idx = this.fields.map((o) => o.key).indexOf("start_at");
    if (idx > 0) {
      this.fields[idx].props!.options = [];
      this.form.patchValue({
        start_at: "",
      });
    }

    if (operation_id) {
      //verificação para que se nao tiver nenhum valor para this.data.schedule.selected_day
      //ele atribui para essa variavel essa variavel this.form.value.schedule_date que tem
      //o valor da data selecionado no formulario assim prosseguindo para a formatação da data
      //se nao tiver valor esse passa direto

      if (!this.data.schedule.selected_day) {
        this.data.schedule.selected_day = this.form.value.schedule_date;
      }

      const options = {
        department_id: this.use_department === false ? 1 : department_id,
        operation_id: operation_id,
        suboperation_id: suboperation_id,
        day: format(
          new Date(
            this.data.schedule.selected_day ?? this.data.schedule.start_at,
          ),
          "yyyy-MM-dd",
        ),
        ofsset: this.offset,
      };

      this.agendamentoService.multi_time_slots(options).subscribe({
        next: (r: any) => {
          const idx = this.fields.map((o) => o.key).indexOf("start_at");
          if (idx > 0) {
            this.fields[idx].props!.options = r;
          }

          if (idx > -1) {
            this.fields[idx].props!.change = () => {
              this.scheduleSlotValidation(options);
            };
          }
        },
        error: (error) => {
          console.log("error", error);
        },
      });
    }
  }

  scheduleSlotValidation(options: any) {
    options = {
      ...options,
      vehicle_type: this.form.value.vehicle_type ?? null,
      start_at: this.form.value.start_at ?? null,
    };
    this.agendamentoService
      .scheduleSlotValidation(options)
      .subscribe((r: any) => {
        this.validationMsgs = r.msg ?? [];
      });
  }

  setDataIntoForm() {
    if (this.data.schedule.id) {
      console.log("EDÇÃO DE PRÉ/AGENDAMENTO");
      if (this.data.schedule.emails) {
        this.emails = JSON.parse(this.data.schedule.emails);
      }
      this.documents = JSON.parse(this.data.schedule.documents);
      this.fields.forEach((item: any) => {
        let idx = this.fields.map((o) => o.key).indexOf(item.key);
        this.fields[idx].defaultValue =
          item.type === "checkbox"
            ? String(this.data.schedule[item.key] || false) == "true"
            : this.data.schedule[item.key];

        if (item.props?.type === "array" && this.data.schedule[item.key]) {
          const parsedArray = this.data.schedule[item.key].split(",") ?? [];
          this.fields[idx].defaultValue = JSON.stringify(parsedArray);
        }

        if (item.key === "department_id") {
          this.setOperations(this.data.schedule[item.key]);
        }
        if (item.key === "operation_id") {
          this.setSuboperations(this.data.schedule[item.key]);
          this.getDocumentsUploadRules(this.data.schedule[item.key], null);
        }

        if (item.key === "suboperation_id") {
          if (this.data.schedule.id) {
            this.getDocumentsUploadRules(
              this.data.schedule.operation_id,
              this.data.schedule.suboperation_id,
            );
          }
        }
        if (item.key === "days_week") {
          try {
            this.fields[idx].defaultValue = this.data.schedule[item.key]
              ? JSON.parse(this.data.schedule[item.key])
              : [];
          } catch {
            this.fields[idx].defaultValue = [];
          }
        }

        if (item.key === "vehicle_type") {
          this.showBoard(this.data.schedule[item.key]);
        }
        if (item.key === "cpf") {
          if (this.data.schedule[item.key]) {
            this.getDriverByCpf(this.data.schedule[item.key]);
          }
        }
        if (item.key === "cnpj") {
          this.getCarrierByCnpj(this.data.schedule[item.key]);
        }
        if (item.key === "documents") {
          this.showDocuments = true;
        }
        if (item.key === "documents_by_type") {
          this.showDocumentsByType = true;
        }
      });

      if (this.schedule_type == "schedule") {
        console.log("BUSCA SLOTS ------------->");
        this.getTimeSlots(
          this.data.schedule["department_id"],
          this.data.schedule["operation_id"],
          this.data.schedule["suboperation_id"],
        );
      }
    }
  }

  setSuboperations(operation: any) {
    const idx = this.fields.map((o) => o.key).indexOf("suboperation_id");
    if (idx > -1) {
      this.suboperationsFt = this.allSuboperations.filter(
        (item) => item.operation_id === operation,
      );
      this.fields[idx].props!.options = this.suboperationsFt;
    }
  }

  setOperations(department: any) {
    const idx = this.fields.map((o) => o.key).indexOf("operation_id");
    if (idx > -1) {
      //Filtra as operações e mostra apenas as que possuem campo use_in_scheduling como true
      this.operationsFt = this.allOperations.filter(
        (item: any) =>
          item.department_id === department && item.use_in_scheduling === true,
      );
      this.fields[idx].props!.options = this.operationsFt;
    }
  }

  setCpfFieldOptions() {
    const idx = this.fields.map((o) => o.key).indexOf("cpf");
    this.fields[idx].parsers = [
      (value) => {
        if (value) {
          this.getDriverByCpf(value);
        }
        return value;
      },
    ];
    if (this.data.schedule && this.data.schedule.cpf) {
      this.getDriverByCpf(this.data.schedule.cpf);
    }
  }

  setCnpjFieldOptions() {
    const idx = this.fields.map((o) => o.key).indexOf("cnpj");
    this.fields[idx].parsers = [
      (value) => {
        if (value) {
          this.getCarrierByCnpj(value);
        }
        return value;
      },
    ];
    if (this.data.schedule && this.data.schedule.cnpj) {
      this.getCarrierByCnpj(this.data.schedule.cnpj);
    }
  }

  upload() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({
          data: file,
          inProgress: false,
          progress: 0,
          uploaded: false,
        });
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }

  uploadFiles() {
    this.fileUpload.nativeElement.value = "";
    this.files.forEach((file) => {
      !file.uploaded && this.uploadFile(file);
    });
  }

  uploadFile(file: any) {
    const formData = new FormData();
    formData.append("file", file.data);
    formData.append("bucket_name", "scheduling");
    file.inProgress = true;

    if (this.onlyPdf === true && file.data.type !== "application/pdf") {
      this.dialog.open(DialogAlertComponent, {
        data: {
          title: "Error ao executar o upload.",
          dialogType: "ok_only",
          message: "Apenas arquivos do tipo PDF.",
        },
      });
      return;
    }
    this.documentsService
      .uploadBucket(formData)
      .pipe(
        map((event: any) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          this.files = this.files.filter((f) => f != file);

          if (error.error?.message) {
            this.dialog.open(DialogAlertComponent, {
              data: {
                title: "Error ao executar o upload.",
                dialogType: "ok_only",
                message: error.error?.message,
              },
            });
          }
          return of(`${file.data.name} upload failed.`);
        }),
      )
      .subscribe((event: any) => {
        if (typeof event === "object") {
          file.uploaded = true;
          this.documents.push(event.body);
        }
      });
  }

  deleteFile(item: any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma a exclusão?",
        message: `Tem certeza que deseja excluir o arquivo ${item.fileName}?`,
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        const pathname = new URL(item.url).pathname;
        const service = item.bucket ? "deleteBucket" : "delete";
        this.documentsService[service]({
          ...item,
          pathname,
          bucket_name: "scheduling",
        }).subscribe({
          next: (r: any) => {
            if (r.success) {
              const idx = this.documents.findIndex(
                (v: any) => v.fileName === item.fileName,
              );
              this.documents.splice(idx, idx >= 0 ? 1 : 0);
              this.files.splice(idx, idx >= 0 ? 1 : 0);
            }
          },
          error: (error: any) => {
            if (error.error?.message) {
              this.dialog.open(DialogAlertComponent, {
                data: {
                  title: "Error ao executar a exclusão.",
                  dialogType: "ok_only",
                  message: error.error?.message,
                },
              });
            }
          },
        });
      }
    });
  }

  createFileFromBlob(file: Blob, fileName: string) {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(file);
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  getFile(item: any): void {
    const pathname = new URL(item.url).pathname;
    const service = item.bucket ? "downloadFileBucket" : "downloadFile";
    this.documentsService[service]({
      ...item,
      pathname,
      bucket_name: "scheduling",
    }).subscribe({
      next: (val: any) => {
        this.createFileFromBlob(val, item.fileName);
      },
      error: (error: any) => {
        if (error.error?.message) {
          this.dialog.open(DialogAlertComponent, {
            data: {
              title: "Error ao executar o upload.",
              dialogType: "ok_only",
              message: error.error?.message,
            },
          });
        }
      },
    });
  }

  getCarrierByCnpj(cnpj: any) {
    if (cnpj.length > 10) {
      const options = {
        search: cnpj,
      };
      if (!this.data.event || this.data.event.cnpj != cnpj) {
        this.carrierService.index(options).subscribe((r: any) => {
          if (r.id) {
            this.form.patchValue({
              carrier_name: r.name,
            });
            this.aditionalFields.carrier_id = r.id;
          }
        });
      }
    }
  }

  getDriverByCpf(cpf: any) {
    if (cpf.length > 10 || cpf?.document?.length > 10) {
      const options = {
        cpf: cpf?.document || cpf,
      };
      if (!this.data.event || this.data.event.cpf != cpf) {
        this.driverService.showViaCpf(options).subscribe((r: any) => {
          if (r.id) {
            this.form.patchValue({
              driver_name: r.name,
              driver_phone: r.resource.phone,
              driver_cnh: r.resource.cnh,
            });
            this.aditionalFields.driver_id = r.id;
            let idx = this.fields.map((o) => o.key).indexOf("driver_name");
            this.fields[idx].props!.disabled = true;

            if (this.fields.map((o) => o.key).indexOf("driver_phone") > -1) {
              idx = this.fields.map((o) => o.key).indexOf("driver_phone");
              this.fields[idx].props!.disabled = true;
            }

            if (this.fields.map((o) => o.key).indexOf("driver_cnh") > -1) {
              idx = this.fields.map((o) => o.key).indexOf("driver_cnh");
              this.fields[idx].props!.disabled = true;
            }

            if (idx > -1) {
              this.fields[idx].props!.disabled = true;
            }
            //Caso tenha entrado com um CPF inválido pela primeira vez, remover o erro no topo se for passado um CPF válido
            this.validationMsgs = [];
          } else {
            //Se não retornar um driver_id, deixar a driver_id atual null e limpar os campos de nome e cpf do motorista
            this.aditionalFields["driver_id"] = null;
            this.form.patchValue({
              driver_name: "",
              driver_phone: "",
              driver_cnh: "",
              cpf: "",
            });
            //Retorna mensagem informando que não foi encontrado motorista com o CPF especificado
            this.validationMsgs = [];
            this.validationMsgs.push(
              this.translate.instant("Messages.driver_not_found", {
                default: "Não foi encontrado um motorista ativo com esse CPF!",
              }),
            );
          }
        });
      }
    }
  }

  storeSchedule($type?: string) {
    this.form.patchValue({ emails: this.emails });
    //Verifico se o this.form?.value?.cpf é um objeto, caso ele seja significar que estou utilizando o formly-input-search-driver
    //Buscar uma forma de melhorar isso, todos utilizarem o novo componente ou mudar a lógica.
    let driverData = {};
    if (typeof this.form?.value?.cpf == "object") {
      driverData = {
        cpf: this.form?.value?.cpf?.document,
        cnh: this.form?.value?.cpf?.resource?.cnh,
      };
    }

    this.clearUnusedBoards();

    const data = {
      ...this.aditionalFields,
      ...this.form.value,
      ...driverData,
      documents: this.documents,
      schedule_type: this.schedule_type,
      status: this.scheduleStatus,
      //Verifica se o form possui a propriedade carrier_id
      //Se sim atribui o carrier_id.value.id do campo de seleção de transportadora com busca
      //Caso não possua valor utilizara apenas carrier_id.value do campo de seleção de transportadora básico
      //E caso a propriedade não exista ou carrier_id.value não possuir valor, atribui null
      carrier_id: this.form.controls.hasOwnProperty("carrier_id")
        ? this.form.controls.carrier_id.value?.id ??
          this.form.controls.carrier_id.value
        : null,
    };

    const start_at = this.form.get("start_at");
    const schedule_date = this.form.get("schedule_date");

    if (start_at?.value && schedule_date?.value) {
      // Concatena o dia escolhido no datepicker com o horário escolhido
      // a partir dos slots de horário
      let date = format(new Date(schedule_date.value), "yyyy-MM-dd");
      // Divide a string start_at em duas partes, gravando na variável o segundo elemento (horário)
      let time = start_at.value.split("T")[1];
      // Concatena date e hora
      data.start_at = `${date}T${time}`;
    }

    //Incluir número de agendamento se o mesmo existe e foi informado
    if (this.form.getRawValue().hasOwnProperty("schedule_code")) {
      data.schedule_code = this.form.getRawValue().schedule_code
        ? this.form.getRawValue().schedule_code
        : null;
    }

    if (this.form.status === "VALID") {
      if ($type && $type == "edit") {
        data["action"] = "edit";
        data["id"] = this.data.schedule.id;

        this.agendamentoService
          .dinamicScheduleUpdateV1(data)
          .subscribe((r: any) => {
            if (r.error) {
              this.loading = false;
              this.validationMsgs = r.msg;
              //Se mensagem retornar com status new_code_generated, fechar com o status e mensagem como parametros
              //Para que seja aberto o modal informando o usuário que foi utilizado um código diferente do informado
            } else if (r.status == "new_code_generated") {
              this.close({ status: r.status, message: r.msg });
            } else {
              this.close({
                message: this.translate.instant("Messages.edited_schedule_2", {
                  Default: "Agendamento editado com sucesso!",
                }),
              });
            }
          });
      } else {
        if (this.schedule_type == "schedule") {
          this.agendamentoService
            .dinamicScheduleCreateV1(data)
            .subscribe((r: any) => {
              if (r.error) {
                this.loading = false;
                this.validationMsgs = r.msg;
                //Se mensagem retornar com status new_code_generated, fechar com o status e mensagem como parametros
                //Para que seja aberto o modal informando o usuário que foi utilizado um código diferente do informado
              } else if (r.status == "new_code_generated") {
                this.close({ status: r.status, message: r.msg });
              } else {
                this.close({
                  message: this.translate.instant(
                    "Messages.created_schedule_2",
                    {
                      Default: "Agendamento criado com sucesso!",
                    },
                  ),
                });
              }
            });
        } else {
          this.agendamentoService
            .createPreSchedule(data)
            .subscribe((r: any) => {
              if (r.error) {
                this.loading = false;
                this.validationMsgs = r.msg;
              } else {
                this.close({
                  message: this.translate.instant(
                    "Messages.created_schedule_2",
                    {
                      Default: "Agendamento criado com sucesso!",
                    },
                  ),
                });
              }
            });
        }
      }
    }
  }

  clearUnusedBoards() {
    // Limpa as placas que não estão sendo utilizadas
    let id_vehicle = this.form.value.vehicle_type;

    let boards = 0;
    let max_board = 0;

    this.dataVehicleType.filter((item: any) => {
      if (item.id == id_vehicle) {
        boards = item.plate_amount;
      }
      if (item.plate_amount > max_board) {
        max_board = item.plate_amount;
      }
    });

    for (let i = 2; i <= max_board - 1; i++) {
      if (boards == 1) {
        this.form.value.board_cart = " ";
      }
      if (i >= boards) {
        this.form.value[`board_cart${i}`] = " ";
      }
    }
  }

  close(message?: any) {
    this.dialogRef.close(message);
  }

  getTimeZone() {
    let offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    return (
      (offset < 0 ? "+" : "-") +
      ("00" + Math.floor(o / 60)).slice(-2) +
      ":" +
      ("00" + (o % 60)).slice(-2)
    );
  }

  alterStatusEvent($status: string) {
    const data = {
      ...this.aditionalFields,
      ...this.form.value,
      documents: this.documents,
      id: this.data.schedule.id,
      status: $status,
    };

    this.agendamentoService.alterarStatusMv(data).subscribe((r: any) => {
      if (r.error) {
        this.validationMsgs = r.msg;
      } else {
        this.close();
      }
    });
  }

  removeSchedule(id: number) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "Confirma a exclusão?",
        message: `Tem certeza que deseja excluir o agendamento?`,
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.agendamentoService
          .dinamicScheduleDeleteV1(id)
          .subscribe((r: any) => {
            if (r.error) {
              this.validationMsgs = r.msg;
            } else {
              this.close();
            }
          });
      }
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    // Add our email
    if (this.validMail.test(value) === true) {
      if ((value || "").trim()) {
        this.emails.push(value);
      }
      this.showMsgError = false;
    } else {
      //Se o valor informado possuir caracteres especiais, ativa a mensagem de erro em <mat-hint> e limpa o input
      this.showMsgError = true;
    }
    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  remove(email: any): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  uploadedDocuments($docs: Event) {
    this.documents = $docs;
  }
}
