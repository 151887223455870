<mat-card class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSourceTransformed">
    <ng-container [matColumnDef]="col.key" *ngFor="let col of columnsSchema">
      <th mat-header-cell *matHeaderCellDef>
        {{col.label}}
      </th>
      <td mat-cell *matCellDef="let element">
        {{element[col.key]}}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef >Ação</th>
      <td mat-cell *matCellDef="let element" >
        <mat-icon (click)="action('update', element)">task_alt</mat-icon>
      </td>
  </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-card>
