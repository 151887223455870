import { BusinessPartnerService } from 'src/app/account/shared/business-partner.service';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'formly-input-search-supplier',
  templateUrl: './formly-input-search-supplier.component.html',
  styleUrls: ['./formly-input-search-supplier.component.scss']
})
export class FormlyInputSearchSupplierComponent extends FieldType<any> implements OnInit {

  formControlFilter = new FormControl();
  listOptions: any[] = [];
  searching = false;
  suppliers: any = [];

  constructor(private businessPartnerService: BusinessPartnerService) {
    super();
  }

  ngOnInit(): void {
    this.getSupplier('');

    this.formControlFilter.valueChanges.pipe(
      debounceTime(2000),
      tap(() => this.searching = true)
    ).subscribe((value: string) => {
      this.searching = true;
      this.getSupplier(value);
    });

    this.formControl.setValue(this.field.defaultValue);
  }

  getSupplier(search: string): void {
    let param = {
      ref_id: 'supplier_id',
      orderBy: "name",
      sortedBy: "ASC",
      page: 1,
      pageSize: 20,
      search: search
    };

    this.businessPartnerService.getSupplier(param).subscribe((res) => {
      this.suppliers = res.data;
      this.listOptions = this.suppliers.map((supplier: any) => supplier.resource);
      this.searching = false;
    });
  }

  formatCnpj(cnpj: string) {
    if (!cnpj) return 'Sem CNPJ cadastrado.';
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }

  filterOptions(query: string) {
    if (!query) return this.listOptions;
    const lowerQuery = query.toLowerCase();
    return this.listOptions.filter(option => 
      (option.name && option.name.toLowerCase().includes(lowerQuery)) || 
      (option.cpf_cnpj && option.cpf_cnpj.toLowerCase().includes(lowerQuery))
    );
  }
}
