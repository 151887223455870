<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-form-field appearance="outline">
  <mat-label>Operação</mat-label>
  <mat-select  [formControl]="operationSelected">
    <mat-option
    *ngFor="let operation of operations"
    [value]="operation.id"
    >
      {{ operation.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-dialog-actions align="end">
    <button mat-button color="warn" mat-dialog-close>Fechar</button>
    <button mat-raised-button style="color: white;" color="primary" (click)="onSubmit()" [mat-dialog-close]="true">Salvar</button>
</mat-dialog-actions>
