import { FileTypeSelectorComponent } from "./../file-type-selector/file-type-selector.component";
import { Validators } from "@angular/forms";
import { EventsService } from "./../../shared/events.service";
import { merge, Subject, Subscription } from "rxjs";
import { DatePipe } from "@angular/common";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  Router,
} from "@angular/router";
import { ReportDynamicService } from "./../shared/report-dynamic.service";
import {
  debounceTime,
  switchMap,
  map,
  catchError,
  tap,
  startWith,
  filter,
} from "rxjs/operators";
import { UserService } from "src/app/user/shared/user.service";
import { SupportDataService } from "src/app/shared/support-data.service";
import { CarrierService } from "src/app/carrier/shared/carrier.service";
import { DriversService } from "src/app/drivers/shared/drivers.service";
import { UntypedFormControl } from "@angular/forms";
import { UntypedFormBuilder, FormBuilder } from "@angular/forms";
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { of as observableOf } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
// import { MatPaginator } from "@angular/material/paginator";
import { ReportDynamicListComponent } from "./report-dynamic-list/report-dynamic-list.component";
import { TranslateService } from "@ngx-translate/core";
import { GlobalService } from "src/app/shared/util/global.service";
import { WSService2 } from "src/app/shared/websocket/wss2.service";

@Component({
  selector: "app-report-dynamic-filter",
  templateUrl: "./report-dynamic-filter.component.html",
  styleUrls: ["./report-dynamic-filter.component.scss"],
})
export class ReportDynamicFilterComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private reportChunkStarted: Boolean = false;

  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(ReportDynamicListComponent, { static: true })
  reportDynamicListComponent: ReportDynamicListComponent;

  @Output() reloadEvent = new EventEmitter();
  // downloadObserver: Subscription;

  private activePage: string;
  enableDonwloadBtn: Subject<boolean> = new Subject();

  formReportDefinition = this.formBuilder.group({
    file_type: ["xlsx"],
    send_email: ["0"],
  });

  form = this.formBuilder.group({
    file_type: [0],
    send_email: [0],
    filter_type: [0],
    driver_id: [0],
    carrier_id: [0],
    operation: [0],
    department: [0],
    dock_space: [0],
    sys_user: [0],
    day: [0],
    dt_start: [0],
    dt_end: [0],
    column_date: [""],
    boards: [
      "",
      [
        Validators.min(2),
        Validators.pattern("(([\\d\\w])|([a-zA-Z\\w\\d])){2,7}$"),
      ],
    ],
  });

  filters: any = [
    {
      id: 1,
      name: this.translate.instant("Business_model.driver", {
        Default: "Motorista",
      }),
      altName: "driver_id",
    },
    {
      id: 2,
      name: this.translate.instant("Business_model.carrier", {
        Default: "Transportadora",
      }),
      altName: "carrier_id",
    },
    // { id: 3, name: 'Placa cavalo', altName: 'board_horse' },
    // { id: 4, name: 'Placa carreta', altName: 'board_cart' },
    // { id: 5, name: 'Status', altName: 'status' },
    {
      id: 6,
      name: this.translate.instant("Business_model.operation", {
        Default: "Operação",
      }),
      altName: "operation",
    },
    {
      id: 7,
      name: this.translate.instant("Business_model.dock", { Default: "Doca" }),
      altName: "dock_space",
    },
    // { id: 8, name: 'DT', altName: 'dt' },
    // { id: 9, name: 'Data entrada', altName: 'entry' },
    // { id: 10, name: 'Data saída', altName: 'exit' },
    {
      id: 11,
      name: this.translate.instant("Business_model.last_user", {
        Default: "Usuário Final",
      }),
      altName: "sys_user",
    },
    {
      id: 12,
      name: this.translate.instant("Business_model.day", { Default: "Dia" }),
      altName: "day",
    },
    {
      id: 13,
      name: this.translate.instant("Business_model.period", {
        Default: "Período",
      }),
      altName: "range",
    },
    {
      id: 14,
      name: this.translate.instant("Business_model.department_area", {
        Default: "Área/Departamento",
      }),
      altName: "department",
    },
    {
      id: 15,
      name: this.translate.instant("Business_model.license_plate", {
        Default: "Placa",
      }),
      altName: "boards",
    },
  ];
  user: any = [];
  startDate: any;
  endDate: any;
  maxDate: Date;

  optionsFilters: any = {
    driver_id: null,
    carrier_id: null,
    operation: null,
    sys_user: null,
    dock_space: null,
    day: null,
    dt_start: null,
    dt_end: null,
    department: null,
    boards: null,
    column_date: null,
    export: false,
  };

  listExceptions: any[] = [];
  listOtProblems: any[] = [];
  listLoadingDenied: any[] = [];
  listUnloadingDenied: any[] = [];
  listLoadingProblems: any[] = [];
  listReceivedWithException: any[] = [];
  listDirectionProblem: any[] = [];
  listInvalidatedDocumentation: any[] = [];

  driverFilter = new UntypedFormControl();
  carrierFilter = new UntypedFormControl();
  boardHorseFilter = new UntypedFormControl();
  boardCartFilter = new UntypedFormControl();
  operationFilter = new UntypedFormControl();
  departmentFilter = new UntypedFormControl();
  dockFilter = new UntypedFormControl();
  dtFilter = new UntypedFormControl();
  entryFilter = new UntypedFormControl();
  exitFilter = new UntypedFormControl();
  userFilter = new UntypedFormControl();
  lacreFilter = new UntypedFormControl();
  dayFilter = new UntypedFormControl();

  report_title: any = {
    checkin: "Relatório de Check In",
    scheduling: "Relatório de Agendamento",
  };
  title: string = "";

  searching = false;
  carriers: any = [];
  drivers: any = [];
  boardHorses: any = [];
  boardCarts: any = [];
  operations: any = [];
  departments: any = [];
  docks: any = [];
  dts: any = [];
  ids: any = [];
  lacres: any = [];
  users: any = [];
  system_client_id = 0;
  options: any = [];
  active_filters: any = [];
  active_filters_name: any = [];
  columnDate: Array<any> = [];

  showMessage = false;
  blockGenerateReport = false;
  noFilters = true;
  reportLogsList: any[] = [];
  reportJobsStatus: any = {
    1: "Processando Relatório",
    2: "Relatório Gerado",
    3: "Relatório enviado por Email",
  };

  constructor(
    private events: EventsService,
    public datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    private driverService: DriversService,
    private carrierService: CarrierService,
    private supportDataService: SupportDataService,
    private userService: UserService,
    private reportDynamicService: ReportDynamicService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private wssService: WSService2,
  ) {
    this.user = JSON.parse(localStorage.getItem("account") || "{}");
    this.system_client_id = this.user.system_client.id;

    this.supportDataService
      .generic({ name: "ot_problem" })
      .subscribe((r: any) => {
        this.listOtProblems = r.data.resource;
      });

    this.startDate = this.form.value.dt_start;
    this.endDate = this.form.value.dt_end;

    this.supportDataService
      .generic({ name: "loading_denied" })
      .subscribe((r: any) => {
        this.listLoadingDenied = r.data.resource;
      });

    this.supportDataService
      .generic({ name: "unloading_denied" })
      .subscribe((r: any) => {
        this.listUnloadingDenied = r.data.resource;
      });

    this.supportDataService
      .generic({ name: "exception_received" })
      .subscribe((r: any) => {
        this.listReceivedWithException = r.data.resource;
      });

    this.supportDataService
      .generic({ name: "loading_problem" })
      .subscribe((r: any) => {
        this.listLoadingProblems = r.data.resource;
      });

    this.supportDataService
      .generic({ name: "direction_problem" })
      .subscribe((r: any) => {
        this.listDirectionProblem = r.data.resource;
      });

    this.supportDataService
      .generic({ name: "invalidated_documentation" })
      .subscribe((r: any) => {
        this.listInvalidatedDocumentation = r.data.resource;
      });
  }

  ngOnInit() {
    document.addEventListener(
      "visibilitychange",
      () => {
        if (document.visibilityState === "visible") {
          if (this.wssService.socket$.closed.valueOf()) {
            this.wssService.closeConnection();
          } else {
            this.subscribeWebsocket();
          }
        }
      },
      false,
    );
    this.activePage = this.activatedRoute.snapshot.params.page;
    this.title = this.report_title[this.activePage];

    this.initObserversOperationFilter();
    this.loadCarrier();
    this.loadDriver();
    this.loadDocks();
    this.loadDepartments();
    this.loadOperations();
    this.loadUsers();
    this.getReportLogs();
  }

  ngAfterViewInit(): void {
    this.subscribeWebsocket();
  }

  subscribeWebsocket() {
    console.log("subscribeWebSocket");
    let scid = JSON.parse(localStorage.getItem("account")!).system_client.id;
    let user_id = JSON.parse(localStorage.getItem("account")!).id;
    this.wssService.CHANNEL = `/report/${scid}/${user_id}`;
    this.wssService.connect().subscribe((msg: any) => {
      console.log("new message");
      console.log(msg);
      this.getReportLogs();
    });
  }

  loadFilters() {
    this.columnDate = this.reportDynamicListComponent?.columns.filter(
      (column: any) => ["date", "datetime"].includes(column.type),
    );
    // Observable.timer(1).subscribe( () =>{
    this.filters = this.filters.filter(
      (x: any) =>
        !(
          (x.id == 7 && this.docks.length <= 0) ||
          (this.departments.length <= 1 && x.id == 14) ||
          (x.id == 2 && this.user.is_carrier && this.user.carriers.length == 1)
        ),
    );
    // });
  }

  ngOnDestroy() {
    if (!this.wssService.socket$.closed.valueOf()) {
      this.wssService.closeConnection();
    }
  }

  initObserversOperationFilter() {
    this.operationFilter.valueChanges
      .pipe(
        startWith(""),
        debounceTime(500),
        switchMap(() => {
          this.searching = true;
          const options: any = {
            orderBy: "updated_at",
            sortedBy: "DESC",
            pageSize: 10,
            page: 1,
            search: `${this.operationFilter.value}` || "",
          };
          if (this.form.value.department.id) {
            options.department_id = this.form.value.department.id;
          }
          return this.supportDataService.operation(options).pipe(
            catchError(() => {
              this.searching = false;
              return observableOf({ data: [] });
            }),
          );
        }),
        map((response: any) => {
          return response.data;
        }),
      )
      .subscribe((data: any) => {
        this.operations = data.resource;
      });
  }

  // initObserversTable() {
  //   /// Ao entrar na página
  //   merge(
  //     this.reportDynamicListComponent.sort.sortChange,
  //     // this.paginator.page,
  //     this.reloadEvent,
  //   )
  //     .pipe(
  //       startWith({}),
  //       switchMap(() => {
  //         // Object.assign(this.options, {
  //         //   orderBy: this.reportDynamicListComponent.sort.active,
  //         //   sortedBy: this.reportDynamicListComponent.sort.direction,
  //         //   page: this.paginator.pageIndex + 1,
  //         //   pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
  //         // });

  //         if (this.user.department_id.length == 1) {
  //           this.options.department = this.user.department_id[0];
  //         }
  //         return this.reportDynamicService
  //           .report(this.activePage, this.options)
  //           .pipe(
  //             catchError(() => {
  //               return observableOf({ data: [], total: 0, per_page: 0 });
  //             }),
  //           );
  //       }),
  //       tap((response: Response | any) => {
  //         // if (!response.data.length && this.paginator.hasPreviousPage()) {
  //         //   this.paginator.previousPage();
  //         // }
  //         // this.paginator.length = response.total;
  //         // this.paginator.pageSize = response.per_page;
  //       }),
  //       map((response: Response | any) => {
  //         return response.data;
  //       }),
  //     )
  //     .subscribe((data) => {
  //       this.reportDynamicListComponent.dataSource.data = data || [];
  //     });
  // }

  loadCarrier() {
    this.carrierFilter.valueChanges
      .pipe(
        debounceTime(800),
        switchMap(() => {
          this.searching = true;
          const options = {
            orderBy: "carrier_client.updated_at",
            sortedBy: "DESC",
            pageSize: 10,
            page: 1,
            search: `${this.carrierFilter.value}` || "",
          };
          return this.carrierService
            .index(options)
            .pipe(tap(() => (this.searching = false)));
        }),
        map((response: any) => {
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        }),
      )
      .subscribe((data) => {
        this.carriers = data;
      });
    this.carrierFilter.setValue(" ", { emitEvent: true });
  }

  loadDriver() {
    this.driverFilter.valueChanges
      .pipe(
        debounceTime(1000),
        switchMap(() => {
          this.searching = true;
          const options = {
            orderBy: "name",
            sortedBy: "ASC",
            page: 1,
            pageSize: 10,
            search: `${this.driverFilter.value}` || "",
          };
          return this.driverService.index(options).pipe(
            tap(() => (this.searching = false)),
            catchError(() => {
              return observableOf({ total: 0, per_page: 0, data: [] });
            }),
          );
        }),
        map((response: any) => {
          return response.data;
        }),
      )
      .subscribe((data) => {
        this.drivers = data;
      });
    this.driverFilter.setValue(" ", { emitEvent: true });
  }

  loadOperations(department_id?: number) {
    this.operations = [];
    this.operationFilter.setValue("");
  }

  loadDepartments() {
    this.departmentFilter.valueChanges
      .pipe(
        startWith(" "),
        debounceTime(800),
        filter((search) => search != "" || this.departments.length == 0),
        switchMap(() => {
          this.searching = true;
          const options = {
            orderBy: "updated_at",
            sortedBy: "DESC",
            pageSize: 10,
            page: 1,
            search: this.departmentFilter.value?.id || "",
          };
          return this.supportDataService.departments(options);
        }),
        map((response: any) => {
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        }),
      )
      .subscribe((data) => {
        this.departments = data.resource;
        this.loadFilters();
      });
  }

  showExceptions(name: string, ids: string, otherMotives?: string) {
    if (ids && name) {
      switch (name) {
        case "ot_problem": {
          this.listExceptions = this.listOtProblems;
          break;
        }

        case "loading_denied": {
          this.listExceptions = this.listLoadingDenied;
          break;
        }

        case "unloading_denied": {
          this.listExceptions = this.listUnloadingDenied;
          break;
        }

        case "exception_received": {
          this.listExceptions = this.listReceivedWithException;
          break;
        }

        case "loading_problem": {
          this.listExceptions = this.listLoadingProblems;
          break;
        }

        case "direction_problem": {
          this.listExceptions = this.listDirectionProblem;
          break;
        }

        case "invalidated_documentation": {
          this.listExceptions = this.listInvalidatedDocumentation;
          break;
        }

        default: {
          //statements;
          break;
        }
      }

      if (this.listExceptions) {
        const exception_ids = JSON.parse(ids);
        //console.log('any ids?', exception_ids);
        let exceptionNames: any[] = [];

        //console.log(this.listExceptions);
        this.listExceptions.forEach((item) => {
          if (exception_ids.includes(item.value)) {
            exceptionNames.push(item.label);
          }
        });

        if (otherMotives) {
          const otherMotivesArray = JSON.parse(otherMotives);

          otherMotivesArray.forEach((item: any) => {
            exceptionNames.push(item);
          });
        }

        return exceptionNames.join(", ");
      } else {
        return "";
      }
    }

    return "";
  }

  loadDocks() {
    this.dockFilter.valueChanges
      .pipe(
        debounceTime(800),
        filter((search) => search != "" || this.docks.length == 0),
        switchMap(() => {
          this.searching = true;
          const options = {
            orderBy: "updated_at",
            sortedBy: "DESC",
            pageSize: 10,
            page: 1,
            search: `${this.dockFilter.value}` || "",
          };
          return this.supportDataService.dockSpaces(options);
        }),
        map((response: any) => {
          return response.data || { resource: [] };
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        }),
      )
      .subscribe((data) => {
        this.docks =
          data.resource instanceof Array
            ? data.resource
            : data.resource["1"] || [];
      });

    this.dockFilter.setValue(" ", { emitEvent: true });
  }

  loadUsers() {
    this.userFilter.valueChanges
      .pipe(
        debounceTime(800),
        filter((search) => search != "" || this.users.length == 0),
        switchMap(() => {
          this.searching = true;
          const options = {
            orderBy: "updated_at",
            sortedBy: "DESC",
            pageSize: 10,
            page: 1,
            search: `${this.userFilter.value}` || "",
          };
          return this.userService.index(options);
        }),
        map((response: any) => {
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        }),
      )
      .subscribe((data) => {
        this.users = data;
      });
    this.userFilter.setValue(" ", { emitEvent: true });
  }

  addFilter() {
    this.noFilters = false;
    const filter = this.filters.filter(
      (f: any) => f.id === this.form.value.filter_type,
    );
    if (filter["0"]["altName"] == "boards" && this.form.get("boards")?.errors) {
      return;
    }
    if (filter["0"]["altName"] == "sys_user") {
      this.active_filters.push({
        filter: filter[0]["name"],
        value: this.form.value[filter["0"]["altName"]]["full_name"],
        altName: filter["0"]["altName"],
        search: this.form.value[filter["0"]["altName"]]["full_name"],
      });
      this.optionsFilters[filter["0"]["altName"]] = [
        this.form.value[filter["0"]["altName"]]["full_name"],
        ...(this.optionsFilters[filter["0"]["altName"]] || "").split("|"),
      ]
        .filter(Boolean)
        .join("|");
    } else if (filter["0"]["altName"] == "day") {
      // let columnDate =
      //   this.columnDate.find((x) => x.data == this.form.value.column_date) ||
      //   {};
      this.active_filters.push({
        filter: filter[0]["name"],
        altName: filter["0"]["altName"],
        value: this.datePipe.transform(
          this.form.value[filter["0"]["altName"]],
          "dd/MM/yyyy",
        ),
        search:
          this.form.value.column_date +
          ":" +
          this.datePipe.transform(
            this.form.value[filter["0"]["altName"]],
            "yyyy-MM-dd",
          ),
      });
      this.optionsFilters[filter["0"]["altName"]] = [
        this.datePipe.transform(
          this.form.value[filter["0"]["altName"]],
          "yyyy-MM-dd",
        ),
        ...(this.optionsFilters[filter["0"]["altName"]] || "").split("|"),
      ]
        .filter(Boolean)
        .join("|");
      // this.optionsFilters.column_date = columnDate?.data;
    } else if (filter["0"]["altName"] == "range") {
      // Busca o índice do filtro no array
      const fIndex = this.active_filters.findIndex(
        (item: any) => item.altName == "range",
      );
      // let columnDate =
      //   this.columnDate.find((x) => x.data == this.form.value.column_date) ||
      //   {};
      // Se o filtro existir, remove
      if (fIndex >= 0) {
        this.active_filters.splice(fIndex, 1);
      }
      const dt_start = this.datePipe.transform(
        this.form.value.dt_start,
        "dd/MM/yyyy",
      );
      const dt_end = this.datePipe.transform(
        this.form.value.dt_end,
        "dd/MM/yyyy",
      );
      this.active_filters.push({
        filter: filter[0]["name"],
        value: `${dt_start} - ${dt_end}`,
        altName: filter["0"]["altName"],
      });
      this.optionsFilters.dt_start = this.datePipe.transform(
        this.form.value.dt_start,
        "yyyy-MM-dd",
      );
      this.optionsFilters.dt_end = this.datePipe.transform(
        this.form.value.dt_end,
        "yyyy-MM-dd",
      );
      // this.optionsFilters.column_date = columnDate?.data;
    } else if (filter["0"]["altName"] == "operation") {
      this.active_filters.push({
        filter: filter[0]["name"],
        value: [this.form.value.department.name, this.form.value.operation.name]
          .filter((x) => x)
          .join(" - "),
        altName: filter["0"]["altName"],
        search: {
          department: this.form.value.department.id,
          operation: this.form.value.operation.id,
        },
      });
      this.optionsFilters.operation = [
        this.form.value.operation?.id,
        ...(this.optionsFilters.operation || "").split("|"),
      ]
        .filter(Boolean)
        .join("|");
      this.optionsFilters.department = [
        this.form.value.department?.id,
        ...(this.optionsFilters.department || "").split("|"),
      ]
        .filter(Boolean)
        .join("|");
    } else {
      this.active_filters.push({
        filter: filter[0]["name"],
        value:
          this.form.value[filter["0"]["altName"]]["name"] ||
          this.form.value[filter["0"]["altName"]],
        altName: filter["0"]["altName"],
        search:
          this.form.value[filter["0"]["altName"]]["id"] ||
          this.form.value[filter["0"]["altName"]],
      });
      this.optionsFilters[filter["0"]["altName"]] = [
        this.form.value[filter["0"]["altName"]]["id"] ||
          this.form.value[filter["0"]["altName"]],
        ...(this.optionsFilters[filter["0"]["altName"]] || "").split("|"),
      ]
        .filter(Boolean)
        .join("|");
    }

    console.log("filtros escolhidos");
    console.log(this.active_filters);
    this.processFilterNames();

    //Recarrega os dados.
    // this.loadReport(this.optionsFilters);
  }

  processFilterNames() {
    this.active_filters_name = [];
    this.active_filters.forEach((item: any) => {
      const name = `${item.filter} ${item.value}`;
      this.active_filters_name.push(name);
    });
    console.log(this.active_filters_name);
  }

  sendReportRequest() {
    this.loadReport(this.optionsFilters);
  }

  reportDownloadBtnActive() {
    //Habilita o botão de download do relatório se o filtro for adicionado.
    this.globalService.btnReportsDownload = true;
  }

  reportDownloadBtnDesactive() {
    //Desabilita o botão de download do relatório se o filtro for adicionado
    //após verificar se todos os atributos de optionsFilter são nulos, vazios ou falsos.
    const optionsFilterValues = Object.values(this.optionsFilters);
    const allFiltersEmpty = optionsFilterValues.every((value) => {
      return value === null || value === "" || value === false;
    });
    this.globalService.btnReportsDownload = !allFiltersEmpty;
  }

  //Função para limitar o filtro de período dos relatórios
  updateMaxEndDate() {
    let sevenFromStartDate = new Date(this.startDate);

    if (this.endDate != this.startDate) {
      this.endDate = this.startDate;
    }
    sevenFromStartDate.setDate(this.startDate.getDate() + 45);
    this.maxDate = sevenFromStartDate;
  }

  deleteFilter(idx: any, altName: string) {
    const filter = {
      search: "",
      ...this.active_filters.splice(idx, 1).shift(),
    };
    if (altName == "range") {
      this.optionsFilters.dt_start = "";
      this.optionsFilters.dt_end = "";
    } else if (altName == "operation") {
      this.optionsFilters.operation = this.optionsFilters.operation
        .split("|")
        .filter((x: any) => {
          if (!(x && x != filter.search.operation)) {
            filter.search.operation = null;
            return false;
          }
          return true;
        })
        .join("|");
      this.optionsFilters.department = this.optionsFilters.department
        .split("|")
        .filter((x: any) => {
          if (!(x && x != filter.search.department)) {
            filter.search.department = null;
            return false;
          }
          return true;
        })
        .join("|");
    } else {
      this.optionsFilters[altName] = this.optionsFilters[altName]
        .split("|")
        .filter((x: any) => {
          if (!(x && x != filter.search)) {
            filter.search = null;
            return false;
          }
          return true;
        })
        .join("|");
    }
  }

  loadReport(filter_options?: any) {
    this.blockGenerateReport = true;
    // this.paginator.pageIndex = 0;
    this.options = {
      file_type: this.formReportDefinition.value.file_type,
      send_email: this.formReportDefinition.value.send_email,
      report_title: this.title,
      origin: this.activePage,
      filter_names: this.active_filters_name,
    };
    if (this.user.department_id.length == 1) {
      this.options = {
        ...this.options,
        department: this.user.department_id[0],
      };
    }
    Object.assign(this.options, filter_options);

    this.reportDynamicService
      .report(this.activePage, this.options)
      .pipe(
        map((response: Response | any) => {
          return response.data;
        }),
        catchError(() => {
          return observableOf({ data: [] });
        }),
      )
      .subscribe(() => {
        this.blockGenerateReport = false;
        this.active_filters = [];
        this.noFilters = true;
        this.getReportLogs();
      });
  }

  downloadReport(): void {
    if (this.reportChunkStarted) return;

    this.reportChunkStarted = true;
    this.optionsFilters.export = true;
    const chunks: any[] = [];

    const reportChunks = (numberOfChunk: number) => {
      this.optionsFilters.chunk = numberOfChunk;
      if (this.optionsFilters.file_type == "xlsx") {
        this.reportDynamicService
          .downloadXlsx(this.activePage, this.optionsFilters)
          .subscribe((data: any) => {
            this.reportChunkStarted = false;
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            a.href = window.URL.createObjectURL(blob);
            a.setAttribute("download", `${Date.now()}.xlsx`);
            a.click();
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
            this.optionsFilters.export = false;
            this.reportChunkStarted = false;
          });
      }
      if (this.optionsFilters.file_type == "csv") {
        this.reportDynamicService
          .downloadCsv(this.activePage, this.optionsFilters)
          .pipe(
            catchError(() => {
              return observableOf({ csv: "", rows: 0 });
            }),
          )
          .subscribe((data: any) => {
            try {
              console.log("csv");
              chunks.push(data.csv || "");
              if (numberOfChunk <= 10 && data.rows >= 10000) {
                reportChunks(numberOfChunk + 1);
              } else if (chunks.length > 0) {
                this.createFileFromBlob(
                  chunks.reduce((textConcat, text, index) => {
                    if (index > 0 && text)
                      return (
                        textConcat + text.substring(text.indexOf("\n") + 1)
                      );
                    return textConcat + text;
                  }),
                );
                this.optionsFilters.export = false;
                this.reportChunkStarted = false;
              }
            } catch (ex) {
              this.optionsFilters.export = false;
              this.reportChunkStarted = false;
            }
          });
      }
    };
    reportChunks(0);
  }

  createFileFromBlob(file: any) {
    console.log("createFileFromBlob");
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    const blob = new Blob(["\uFEFF" + file], {
      type: "text/csv; charset=utf-18",
    });
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("download", `${Date.now()}.csv`);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  openFileTypeSelector() {
    const dialogRef = this.dialog.open(FileTypeSelectorComponent, {
      panelClass: ["dialog-small"],
      disableClose: false,
      data: {
        title: this.translate.instant("Messages.export_file", {
          Default: "Exportar arquivo como...",
        }),
        item: {},
        // action: action,
      },
    });

    dialogRef.afterClosed().subscribe((r) => {
      console.log(r);
      this.optionsFilters.file_type = r;
      this.downloadReport();
    });
  }

  getReportLogs() {
    this.reportDynamicService.reportLogs().subscribe((r: any) => {
      this.reportLogsList = r.data;
    });
  }
}
