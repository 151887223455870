
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss']
})
export class SchedulingComponent implements OnInit {
  account: any;
  config_scheduling: any;

  constructor() { }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem('account') || "{}");
    this.config_scheduling = this.account.system_client.resource.environment.pages['configurations'].scheduling;
  }
}
