<div mat-dialog-content>
  <form [formGroup]="form">
    <div class="tkg-contacts">
      <formly-form
        [model]="model"
        [fields]="fields"
        [options]="options"
        [form]="form"
      ></formly-form>
      <!-- <button
        mat-icon-button
        color="primary"
        aria-label="Adicionar"
        (click)="change()"
      >
        <mat-icon>add</mat-icon>
      </button> -->
    </div>
    <!-- </div> -->
  </form>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button color="warn" mat-dialog-close>Cancelar</button>
  <button mat-button mat-dialog-close>
    <mat-icon>delete</mat-icon> Remover Todos
  </button>
  <button
    mat-raised-button
    color="primary"
    style="color: white"
    cdkFocusInitial
    (click)="add()"
  >
    Adicionar
    <mat-icon iconPositionEnd>add</mat-icon>
  </button>
</div>
