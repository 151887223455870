import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith } from "rxjs/operators";
import { Account } from 'src/app/account/account';
import { AgendamentoService } from "src/app/multiagendamento/shared/agendamento.service";

@Component({
  selector: 'app-formly-ss-schedule',
  templateUrl: './formly-ss-schedule.component.html',
  styleUrls: ['./formly-ss-schedule.component.scss']
})
export class FormlySsScheduleComponent extends FieldType {

  constructor(private agendamentoService: AgendamentoService) {
    super();
  }

  _formControl = new FormControl();
  filter:Observable<any>;
  ngModule:any;
  selectedItem = false;
  filter_status:[] = [];
  status: string;

  ngOnInit(){

    const accountStorage: string = localStorage.getItem('account') || '';
    const account: Account = JSON.parse(accountStorage);
    this.filter_status = account.system_client.resource.environment.components_options.SelectSearchAprovedSchedules.filter_status

    this.status = this.filter_status.join(',')

    this._formControl.valueChanges.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          startWith(''),
          map(value => value)
          ).subscribe(value => {
            if(typeof value === 'string') {
              this.filter = this.agendamentoService.getEntrySchedules({search: value}, this.status);
            }
          });

  }

  clear(){
    this.ngModule = "";
    this.formControl.setValue("");
  }

  displaySelected(opt: any) {
    if(opt){
      this.formControl.setValue(opt.id);
      return opt && opt.name ? opt.name : '';
    }
  }
}
