import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreModule } from "../core/core.module";
import { ConfigurationsRoutingModule } from "./configurations-routing.module";
import { ConfigurationsComponent } from "./configurations.component";
import { DriverPanelComponent } from "./driver-panel/driver-panel.component";
import { SchedulingComponent } from "./scheduling/scheduling.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { MatButtonModule } from "@angular/material/button";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ScheduleWindowComponent } from "./components/schedule-window/schedule-window.component";
import { ScheduleBlocksComponent } from "./components/schedule-blocks/schedule-blocks.component";
import { ScheduleLimitsComponent } from "./components/schedule-limits/schedule-limits.component";
import { ScheduleAntecedenceComponent } from "./components/schedule-antecedence/schedule-antecedence.component";
import { ScheduleWindowDialogComponent } from "./components/schedule-window-dialog/schedule-window-dialog.component";
import { ScheduleBlocksDialogComponent } from "./components/schedule-blocks-dialog/schedule-blocks-dialog.component";
import { ScheduleLimitsDialogComponent } from "./components/schedule-limits-dialog/schedule-limits-dialog.component";
import { ScheduleAntecedenceDialogComponent } from "./components/schedule-antecedence-dialog/schedule-antecedence-dialog.component";
import { ScheduleStatusComponent } from "./components/schedule-status/schedule-status.component";
import { ScheduleStatusDialogComponent } from "./components/schedule-status-dialog/schedule-status-dialog.component";
import { ScheduleLimitDinamicDialogComponent } from "./components/schedule-limit-dinamic-dialog/schedule-limit-dinamic-dialog.component";
import { FormlyModule } from "@ngx-formly/core";
import { CheckinComponent } from './checkin/checkin.component';
import { CheckinCarrierRulesComponent } from './components/checkin-carrier-rules/checkin-carrier-rules.component';
import { CheckinCarrierRulesDialogComponent } from './components/checkin-carrier-rules-dialog/checkin-carrier-rules-dialog.component';
import { DocksSettingsComponent } from './docks-settings/docks-settings.component';
import { LanguagesComponent } from './languages/languages.component';
import { VisualFormBuilderComponent } from './visual-form-builder/visual-form-builder.component';
import { RegistrationsSettingsComponent } from './registrations-settings/registrations-settings.component';
import { ShiftDialogComponent } from './shifts/dialogs/shift-dialog/shift-dialog.component';
import { ShiftTabsComponent } from './shifts/shift-tabs/shift-tabs.component';
import { TabShiftSchedulesComponent } from './shifts/shift-tabs/tab-shift-schedules/tab-shift-schedules.component';
import { TabShiftComponent } from './shifts/shift-tabs/tab-shift/tab-shift.component';
import { ConversionPipe } from './shifts/conversion.pipe';
import { ShiftShiftDialogComponent } from './shifts/dialogs/shift-shift-dialog/shift-shift-dialog.component';
import { NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { PreScheduleStatusComponent } from './components/pre-schedule-status/pre-schedule-status.component';
import { PreScheduleStatusDialogComponent } from './components/pre-schedule-status-dialog/pre-schedule-status-dialog.component';
import { PreScheduleDocumentComponent } from './components/pre-schedule-document/pre-schedule-document.component';
import { PreScheduleDocumentDialogComponent } from './components/pre-schedule-document-dialog/pre-schedule-document-dialog.component';
import { ScheduleWindowByWeightComponent } from './components/schedule-window-by-weight/schedule-window-by-weight.component';
import { ScheduleWindowByWeightDialogComponent } from './components/schedule-window-by-weight-dialog/schedule-window-by-weight-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        ConfigurationsRoutingModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatRadioModule,
        MatCardModule,
        MatTabsModule,
        MatButtonModule,
        MatGridListModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatDatepickerModule,
        FormlyModule.forRoot({
            validationMessages: [
                { name: "required", message: "Este campo é obrigatório" },
            ],
        }),
        NgxMatTimepickerModule,
        NgxMatMomentModule
    ],
    declarations: [
        ConfigurationsComponent,
        DriverPanelComponent,
        SchedulingComponent,
        ScheduleWindowComponent,
        ScheduleBlocksComponent,
        ScheduleLimitsComponent,
        ScheduleStatusComponent,
        ScheduleAntecedenceComponent,
        ScheduleWindowDialogComponent,
        ScheduleBlocksDialogComponent,
        ScheduleLimitsDialogComponent,
        ScheduleAntecedenceDialogComponent,
        ScheduleStatusDialogComponent,
        ScheduleLimitDinamicDialogComponent,
        CheckinComponent,
        CheckinCarrierRulesComponent,
        CheckinCarrierRulesDialogComponent,
        DocksSettingsComponent,
        LanguagesComponent,
        VisualFormBuilderComponent,
        RegistrationsSettingsComponent,
        ShiftDialogComponent,
        ShiftTabsComponent,
        TabShiftSchedulesComponent,
        TabShiftComponent,
        ConversionPipe,
        ShiftShiftDialogComponent,
        PreScheduleStatusComponent,
        PreScheduleStatusDialogComponent,
        PreScheduleDocumentComponent,
        PreScheduleDocumentDialogComponent,
        ScheduleWindowByWeightComponent,
        ScheduleWindowByWeightDialogComponent,
    ],
    exports: [
        MatCardModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
    ]
})
export class ConfigurationsModule {}
