import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { Docas } from "./docas.interface";
// import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: "root"
})
export class SupportDataService {
  constructor(private http: HttpClient) { }

  dinamicTable(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  generic(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }
  multi(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/multi`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  entry_type(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=entry_type`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  vehicle_type(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=vehicle_type`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getVehicleType(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=vehicle_type`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response.data.resource || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  operation(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=operation_type`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getOperation(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=operation_type`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response.data.resource || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getSuboperation(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=suboperation_type`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response.data.resource || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  // @Injectable()
  // dataOperation(): Observable<any[]> {
  //   const data = this.operation;
  //   console.log(data);
  //   return of(data);
  // }

  suboperation(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=suboperation_type`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  category(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=category_list`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  actions(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=actions`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  status(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=status`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  scheduleStatus(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=schedule_status_list`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }



  checkin_mht(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=checkin_mht`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  operationLoreal(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=operation_type`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  parkingType() {
    const url = `${environment.apiUrl}/support-data/web?name=parking_space_type`;
    return this.http.get(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  parkingSpaces() {
    const url = `${environment.apiUrl}/support-data/web?name=parking_space`;
    return this.http.get(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  dockSpaces(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=dock_space`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  typeSpaces(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=spaces_type`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  dockSpacesLoreal(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=dock_space`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  loadedSpaces() {
    const url = `${environment.apiUrl}/support-data/web?name=loaded_space`;
    return this.http.get(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  outBoundSpaces() {
    const url = `${environment.apiUrl}/support-data/web?name=outbound_space`;
    return this.http.get(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  finishSpaces() {
    const url = `${environment.apiUrl}/support-data/web?name=finish_space`;
    return this.http.get(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  updateDockSpace(data: any, id: string, params?: any): Observable<Docas> {
    console.log(id)
    const url = `${environment.apiUrl}/support-data/dock-space/${id}`;
    const options = { params: params };

    return this.http.put<Docas>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  updateDockMaintenance(
    data: any,
    id: string,
    params?: any
  ): Observable<Docas> {
    const url = `${environment.apiUrl}/support-data/maintanence-dock-space/${id}`;
    const options = { params: params };

    return this.http.put<Docas>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  departments(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=departments`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  spacesType(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=spaces_type`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  // updateClientParams(data: any): Observable<any> {
  //   const url = `${environment.apiUrl}/support-data/update-params`;
  //   const options = { params: params };

  //   return this.http.put<Docas>(url, data, options).pipe(
  //     map((response: Response | any) => response || {}),
  //     catchError(err => throwError(() => err || new Error('Server error')))
  //   );
  // }

  updateDock(data: any, id?: string, params?: any): Observable<Docas> {
    const url = `${environment.apiUrl}/support-data/setting-dock-space`;
    const options = { params: params };

    return this.http.put<Docas>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  //bloquear/desbloquear o processo de ocupação de doca de forma automatica
  updateDockAuto(data: any, id?: string, params?: any): Observable<Docas> {
    const url = `${environment.apiUrl}/support-data/update-dock-auto`;
    const options = { params: params };

    return this.http.put<Docas>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  getDockSpaces(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/dock-space-all`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  reportGeneric(options?: any):Observable<Record<string, any>[]> {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/report-generic`;
    return this.http.get<Record<string, any>[]>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  pageFilters(page: string) {
    const url = `${environment.apiUrl}/support-data/page-filters/${page}`;
    return this.http.get<Record<string, any>[]>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }

  addNewItem2List(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/support-data/addNewItem2List`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || "Server error"))
    );
  }

  editItemOnList(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/support-data/editItemOnList`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || "Server error"))
    );
  }

  deleteItemOnList(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/support-data/deleteItemOnList`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || "Server error"))
    );
  }

  deleteItemOnObject(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/support-data/deleteItemOnObject`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || "Server error"))
    );
  }

  updateList(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/support-data/update-list`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || "Server error"))
    );
  }

  updateObject(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/support-data/update-object`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError(err => throwError(err || "Server error"))
    );
  }


  getFlowTemplates(options?: any) {
    const params = new HttpParams({ fromObject: options })
    const url = `${environment.apiUrl}/support-data/web?name=flow_template`;
    return this.http.get(url, { params }).pipe(
      map((response: Response | any) => response.data.resource || {}),
      catchError(err => throwError(() => err || new Error('Server error')))
    );
  }
}
