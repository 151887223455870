import { CheckinService } from './../../checkin/shared/checkin.service';
import { UntypedFormControl } from '@angular/forms';
import { SupportDataService } from './../support-data.service';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-change-operation',
  templateUrl: './dialog-change-operation.component.html',
  styleUrls: ['./dialog-change-operation.component.scss']
})
export class DialogChangeOperation implements OnInit {

  operations: any[] = [];
  allData: any;
  getData: string = "";
  AllOperations: any;
  AllDepartments: any;
  operationSelected: UntypedFormControl = new UntypedFormControl();
  dialogResult: any;

  constructor(
    private supportDataService: SupportDataService,
    private checkinService: CheckinService,
    public dialogRef: MatDialogRef<DialogChangeOperation>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getOperations();
  }

  getOperations(){
    this.supportDataService.generic({name: "operation_type"}).subscribe((r: any) => {
      this.operations = r.data.resource.filter((item: any) => item.department_id == this.data.item.resource.department_id);
      this.operationSelected.setValue(this.data.item.resource.operation_id || this.data.item.resource.operation);
    });
  }

  onSubmit() {
    this.dialogResult = {
      operation_id: this.operationSelected.value,
      id: this.data.item.id,
      changeOperation: true
    }
    this.checkinService.updateField('checkins-update-field', this.dialogResult).subscribe((r:any)=>{
      if(r.status == 200) {
        this.dialogRef.close(this.dialogResult);
      }
    });
  }
}
