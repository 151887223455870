import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, map, Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class BusinessPartnerService {
  constructor(private http: HttpClient) {}

  all(options?: any): Observable<any> {
    const url = `${environment.apiUrl}/business-partners`;
    const httpParams = Object.assign({}, options);
    const params = new HttpParams({ fromObject: httpParams });

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }

  getSupplier(options?: any): Observable<any> {
    const url = `${environment.apiUrl}/business-partners`;
    const httpParams = Object.assign({}, options);
    const params = new HttpParams({ fromObject: httpParams });

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }

  create(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/business-partners`;
    const options = { params: params };

    return this.http.post<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(() => err || new Error("Server error"))),
    );
  }

  show(id: string, options?: any): Observable<any> {
    const url = `${environment.apiUrl}/business-partners/${id}`;
    const httpParams = Object.assign({}, options);
    const params = new HttpParams({ fromObject: httpParams });

    return this.http.get<any>(url, { params }).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }

  update(data: any, params?: any): Observable<any> {
    const url = `${environment.apiUrl}/business-partners`;
    const options = { params: params };

    return this.http.put<any>(url, data, options).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }

  delete(id: string): Observable<any> {
    const url = `${environment.apiUrl}/business-partners/${id}`;

    return this.http.delete<any>(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }
}
