import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { CheckinService } from "src/app/checkin/shared/checkin.service";
import { DialogConfirmComponent } from "../dialog-confirm/dialog-confirm.component";

@Component({
  selector: "app-weight-dialog",
  templateUrl: "./weight-dialog.component.html",
  styleUrls: ["./weight-dialog.component.scss"],
})
export class WeightDialogComponent {
  weight_type: any = [
    {
      id: 1,
      name: "Pesagem inicial",
      allow_multiple: false,
      incremental: true,
    },
    {
      id: 2,
      name: "Pesagem intermediária",
      allow_multiple: true,
      incremental: true,
    },
    { id: 3, name: "Pesagem final", allow_multiple: false, incremental: true },
    {
      id: 4,
      name: "Pesagem de embalagem",
      allow_multiple: true,
      incremental: false,
    },
    { id: 5, name: "Pesagem NF", allow_multiple: true, incremental: false },
  ];

  columnsSchema: any[] = [
    { key: "weight_type_id", label: "Tipo de pesagem" },
    { key: "weight", label: "Peso (Kg)" },
    { key: "created_at", label: "Registrado em" },
  ];

  displayedColumns: string[] = ["weight_type_id", "weight", "created_at"];
  dataSource: any[] = [];
  ds_original_size: number = 0;

  // INÍCIO - Transformação valores nas colunas da tabela
  // Gerencia a transformação das colunas. Para cada transformMethods deve existir uma chave com o mesmo nome em transformData.
  // Se transformaData não for necessário para a transformação, usar um array vazio
  transformData: any = {
    weight_type_id: this.weight_type,
    created_at: [],
  };
  transformMethods: any = {
    weight_type_id: this.showWeightType,
    created_at: this.formatDate,
  };
  // FIM - Transformação valores nas colunas da tabela

  unique_weight_type: any[] = [];
  consolidationRule: any = {
    initial_id: [1],
    final_id: [3],
    diff_ids: [4, 5],
  };
  initial: number = 0;
  total: number = 0;
  diff_sum: number = 0;

  summary: any;

  form = this.formBuilder.group({
    weight_type_id: ["", Validators.required],
    weight: ["", Validators.required],
  });

  display_message = false;
  message = "";
  user: any;

  constructor(
    private dialog: MatDialog,
    private checkinService: CheckinService,
    public formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<WeightDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log("-----data");
    console.log(this.data);

    const stored_data = localStorage.getItem("account") || "{}";
    this.user = JSON.parse(stored_data);
    if (this.data.item.resource.hasOwnProperty("weight_control")) {
      this.dataSource = this.data.item.resource.weight_control;
      this.ds_original_size = JSON.parse(
        JSON.stringify(this.dataSource),
      ).length;
      this.summary = this.data.item.resource.weight_summary;
      console.log("datasource -------");
      console.log(this.dataSource);

      this.getLastIncrementalWeight();
      this.getInitialWeight();
      this.calculateWeightSummary();
    }
  }

  addValue2DS() {
    if (!this.unique_weight_type.includes(this.form.value.weight_type_id)) {
      if (!this.checkIfRecordIsAllowed(this.form.value.weight_type_id)) {
        this.unique_weight_type.push(this.form.value.weight_type_id);
      }
      const created_at = new Date();
      const opt = {
        id: this.dataSource.length + 1,
        created_at: created_at.toISOString(),
        created_by: this.user.id,
        weight: parseFloat(this.form.value.weight),
        weight_type_id: this.form.value.weight_type_id,
      };
      this.dataSource.push(opt);
      this.dataSource = this.dataSource.slice();
    } else {
      let msgPart = this.weight_type.filter(
        (ft: any) => ft.id === parseFloat(this.form.value.weight_type_id),
      );
      this.message = `Pode ser adicionada apenas uma ${msgPart[0].name}`;
      this.display_message = true;
      setTimeout(() => {
        this.display_message = false;
      }, 3000);
    }

    this.form.reset();
    this.getLastIncrementalWeight();
    this.getInitialWeight();
    this.calculateWeightSummary();
  }

  save() {
    const options = {
      id: this.data.id,
      weight_control: this.dataSource,
      weight_summary: this.calculateWeightSummary(),
    };

    console.log(options);

    this.checkinService.updateStatus(options).subscribe((res: any) => {
      this.dialogRef.close();
    });
  }

  cancel() {
    console.log(this.ds_original_size);
    console.log(this.dataSource.length);
    if (this.dataSource.length > this.ds_original_size) {
      // console.log('Você possui pesagens não salvas. Tem certeza que deseja fechar esta janela?');
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          title: "Confirmar fechamento com dados não salvo",
          message: `Você possui pesagens não salvas. Tem certeza que deseja fechar esta janela?`,
        },
      });

      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  showWeightType(id: number, data: any) {
    const item: any = data.filter((ft: any) => ft.id === id);
    return item[0].name;
  }

  formatDate(date: string, data: any) {
    const dt = new Date(date);
    const formatTo = new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    const formattedDate = formatTo.format(dt);
    return formattedDate;
  }

  checkIfRecordIsAllowed(id: number) {
    const ft = this.weight_type.filter((item: any) => item.id === id);
    const res = ft[0].allow_multiple;
    return res;
  }

  getInitialWeight(): void {
    const ft = this.dataSource.filter((ft: any) =>
      this.consolidationRule.initial_id.includes(ft.weight_type_id),
    );
    if (ft.length > 0) {
      this.initial = ft[0].weight;
    }
  }

  getLastIncrementalWeight(): void {
    const ids_increment = this.weight_type.filter(
      (ft: any) => ft.incremental === true,
    );
    const ft = this.dataSource.filter((ft: any) =>
      ids_increment.map((item: any) => item.id).includes(ft.weight_type_id),
    );
    if (ft.length > 0) {
      const res: any = ft.slice(-1);
      this.total = res[0].weight;
    }
  }

  findWeightByType(type: number) {
    return this.dataSource
      .filter((ft: any) => ft.weight_type_id === type)
      .map((item: any) => item.weight)
      .reduce((prev, curr) => prev + curr, 0);
  }

  getAttributeByWeightType(weight_type: number, attribute: string) {
    const data = this.dataSource.filter(
      (ft: any) => ft.weight_type_id === weight_type,
    );
    if (data.length > 0) {
      return data[0][attribute as keyof typeof data];
    } else {
      return "";
    }
  }

  calculateWeightSummary() {
    this.summary = {
      start: this.findWeightByType(1) || 0,
      start_created_at: this.getAttributeByWeightType(1, "created_at"),
      start_created_by: this.getAttributeByWeightType(1, "created_by"),
      end: this.findWeightByType(3) || 0,
      end_created_at: this.getAttributeByWeightType(3, "created_at"),
      end_created_by: this.getAttributeByWeightType(3, "created_by"),
      packaging: this.findWeightByType(4) || 0,
      packaging_created_at: this.getAttributeByWeightType(4, "created_at"),
      packaging_created_by: this.getAttributeByWeightType(4, "created_by"),
      invoice: this.findWeightByType(5) || 0,
      invoice_created_at: this.getAttributeByWeightType(5, "created_at"),
      invoice_created_by: this.getAttributeByWeightType(5, "created_by"),
      diff: 0,
      net: 0,
    };

    if (this.data.item.resource.operation === 1 && this.summary.end > 0) {
      this.summary.net = this.summary.end - this.summary.start;
      this.summary.diff =
        this.summary.end -
        (this.summary.start + this.summary.invoice + this.summary.packaging);
    }

    if (this.data.item.resource.operation === 2 && this.summary.end > 0) {
      this.summary.net = this.summary.start - this.summary.end;
      this.summary.diff =
        this.summary.end +
        this.summary.invoice +
        this.summary.packaging -
        this.summary.start;
    }

    return this.summary;
  }
}
