<div>
  <button mat-button routerLink="/admin/report-dynamic">
    <mat-icon>arrow_back</mat-icon>
    Relatórios
  </button>
</div>

<mat-card class="report-panel">
  <mat-card-content>
    <div
      class="container"
      fxLayout
      fxLayout.xs="column"
      fxLayoutAlign="rigth"
      fxLayoutGap="10px"
    >
      <h2>Opções do {{ title }}</h2>
      <form [formGroup]="formReportDefinition">
        <p class="toggle-item">
          Tipo de arquivo
          <mat-button-toggle-group
            name="file_type"
            aria-label="Tipo de arquivo"
            formControlName="file_type"
          >
            <mat-button-toggle value="xlsx">Excel (xlsx)</mat-button-toggle>
            <mat-button-toggle value="csv">Texto (csv)</mat-button-toggle>
          </mat-button-toggle-group>
        </p>
        <p class="toggle-item">
          Enviar por email
          <mat-button-toggle-group
            name="file_type"
            aria-label="Enviar por email"
            formControlName="send_email"
          >
            <mat-button-toggle value="0">Não</mat-button-toggle>
            <mat-button-toggle value="1">Sim</mat-button-toggle>
          </mat-button-toggle-group>
        </p>
      </form>
      <form autocomplete="off" [formGroup]="form" novalidate>
        <mat-form-field appearance="outline">
          <mat-label>{{
            "Business_model.filter_type"
              | translate: { Default: "Tipo de filtro" }
          }}</mat-label>
          <mat-select formControlName="filter_type">
            <mat-option *ngFor="let filter of filters" [value]="filter.id">
              {{
                filter.translation || filter.name
                  | translate: { Default: filter.name }
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          *ngIf="
            (form.value.filter_type == 14 || form.value.filter_type == 6) &&
            this.user.department_id.length > 1
          "
        >
          <mat-label>{{
            "Business_model.department_area"
              | translate: { Default: "Área/Departamento" }
          }}</mat-label>
          <mat-select
            [placeholder]="
              'Business_model.area'
                | translate: { Default: 'Área/Departamento' }
            "
            formControlName="department"
            (selectionChange)="loadOperations($event.value.id)"
          >
            <mat-option>
              <ngx-mat-select-search
                [formControl]="departmentFilter"
                [placeholderLabel]="
                  'Business_model.search_area'
                    | translate: { Default: 'Buscar Área' }
                "
                [noEntriesFoundLabel]="
                  'Business_model.no_result'
                    | translate: { Default: 'Nenhum resultado encontrado' }
                "
              ></ngx-mat-select-search>
            </mat-option>

            <mat-option *ngFor="let item of departments" [value]="item">
              {{
                item.translation || item.name
                  | translate: { Default: item.name }
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          *ngIf="form.value.filter_type == 1"
        >
          <mat-label>{{
            "Business_model.driver" | translate: { Default: "Motorista" }
          }}</mat-label>
          <mat-select
            formControlName="driver_id"
            [placeholder]="
              'Business_model.driver' | translate: { Default: 'Motorista' }
            "
          >
            <mat-option>
              <ngx-mat-select-search
                [clearSearchInput]="false"
                [searching]="searching"
                [formControl]="driverFilter"
                [placeholderLabel]="
                  'Business_model.search_driver'
                    | translate: { Default: 'Buscar Motorista' }
                "
                [noEntriesFoundLabel]="
                  'Business_model.no_result'
                    | translate: { Default: 'Nenhum resultado encontrado' }
                "
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let item of drivers" [value]="item">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          *ngIf="
            form.value.filter_type == 2 &&
            !(this.user.is_carrier && this.user.carriers.length == 1)
          "
        >
          <mat-label>{{
            "Business_model.carrier" | translate: { Default: "Transportadora" }
          }}</mat-label>
          <mat-select
            formControlName="carrier_id"
            [placeholder]="
              'Business_model.carrier'
                | translate: { Default: 'Transportadora' }
            "
          >
            <mat-option>
              <ngx-mat-select-search
                [clearSearchInput]="false"
                [searching]="searching"
                [formControl]="carrierFilter"
                [placeholderLabel]="
                  'Business_model.search_carrier'
                    | translate: { Default: 'Buscar Transportadora' }
                "
                [noEntriesFoundLabel]="
                  'Business_model.no_result'
                    | translate: { Default: 'Nenhum resultado encontrado' }
                "
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let item of carriers" [value]="item">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          *ngIf="form.value.filter_type == 6"
        >
          <mat-label>{{
            "Business_model.operation" | translate: { Default: "Operação" }
          }}</mat-label>
          <mat-select
            formControlName="operation"
            [placeholder]="
              'Business_model.operation' | translate: { Default: 'Operação' }
            "
          >
            <mat-option>
              <ngx-mat-select-search
                [formControl]="operationFilter"
                [placeholderLabel]="
                  'Business_model.operation'
                    | translate: { Default: 'Operação' }
                "
                noEntriesFoundLabel="'Business_model.no_result' | translate:{Default: 'Nenhum resultado encontrado'}"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let item of operations" [value]="item">
              {{
                item.translation || item.name
                  | translate: { Default: item.name }
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          *ngIf="form.value.filter_type == 7"
        >
          <mat-label>{{
            "Business_model.dock" | translate: { Default: "Doca" }
          }}</mat-label>
          <mat-select formControlName="dock_space" placeholder="Doca">
            <mat-option>
              <ngx-mat-select-search
                [formControl]="dockFilter"
                [placeholderLabel]="
                  'Business_model.search_docks'
                    | translate: { Default: 'Buscar Docas' }
                "
                [noEntriesFoundLabel]="
                  'Business_model.no_result'
                    | translate: { Default: 'Nenhum resultado encontrado' }
                "
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let item of docks" [value]="item">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          *ngIf="form.value.filter_type == 11"
        >
          <mat-label>{{
            "Business_model.last_user" | translate: { Default: "Usuário Final" }
          }}</mat-label>
          <mat-select
            formControlName="sys_user"
            [placeholder]="
              'Business_model.last_user'
                | translate: { Default: 'Usuário Final' }
            "
          >
            <mat-option>
              <ngx-mat-select-search
                [formControl]="userFilter"
                [placeholderLabel]="
                  'Business_model.search_user'
                    | translate: { Default: 'Buscar Usuário' }
                "
                [noEntriesFoundLabel]="
                  'Business_model.no_result'
                    | translate: { Default: 'Nenhum resultado encontrado' }
                "
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let item of users" [value]="item">
              {{ item.full_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          *ngIf="form.value.filter_type == 12"
        >
          <mat-label>{{
            "Business_model.day" | translate: { Default: "Dia" }
          }}</mat-label>
          <input matInput [matDatepicker]="picker1" formControlName="day" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>

        <!-- <mat-form-field
          appearance="outline"
          *ngIf="form.value.filter_type == 13"
        >
          <input
            matInput
            [matDatepicker]="picker1"
            [placeholder]="
              'Business_model.initial_data'
                | translate: { Default: 'Data Inicial' }
            "
            [(ngModel)]="startDate"
            (dateChange)="updateMaxEndDate()"
            formControlName="dt_start"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field> -->

        <!-- <mat-form-field
          appearance="outline"
          *ngIf="form.value.filter_type == 13"
        >
          <input
            matInput
            [matDatepicker]="picker2"
            [placeholder]="
              'Business_model.due_data' | translate: { Default: 'Data Final' }
            "
            [min]="startDate"
            [max]="maxDate"
            [(ngModel)]="endDate"
            formControlName="dt_end"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field> -->
        <!-- <mat-form-field
          class="example-full-width"
          appearance="outline"
          *ngIf="form.value.filter_type == 13"
        >
          <mat-label>Escolha o período</mat-label>
          <input
            matInput
            [min]="minDate"
            [max]="maxDate"
            [matDatepicker]="picker"
            formControlName="dt_range"
            (dateChange)="updateMaxEndDate()"
          />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field> -->
        <mat-form-field
          appearance="outline"
          class="tkg-date-range"
          *ngIf="form.value.filter_type == 13"
        >
          <mat-label>Escolha o período</mat-label>
          <mat-date-range-input
            [formGroup]="range"
            [min]="minDate"
            [max]="maxDate"
            [rangePicker]="picker"
          >
            <input
              matStartDate
              formControlName="dt_start"
              placeholder="Data inicial"
            />
            <input
              matEndDate
              formControlName="dt_end"
              placeholder="Data final"
              (dateChange)="updateMinMaxDate()"
            />
          </mat-date-range-input>
          <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error
            *ngIf="range.controls.dt_start.hasError('matStartDateInvalid')"
            >Invalid start date</mat-error
          >
          <mat-error *ngIf="range.controls.dt_end.hasError('matEndDateInvalid')"
            >Invalid end date</mat-error
          >
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          *ngIf="form.value.filter_type == 15"
        >
          <mat-label>{{
            "Business_model.license_plate" | translate: { Default: "Placa" }
          }}</mat-label>
          <input
            type="text"
            oninput="this.value = this.value.toUpperCase()"
            style="text-transform: uppercase"
            autocomplete="off"
            minlength="2"
            maxlength="7"
            formControlName="boards"
            [placeholder]="
              'Business_model.license_plates' | translate: { Default: 'Placas' }
            "
            matInput
          />
          <mat-error *ngIf="form.get('boards')?.errors">
            {{
              "Messages.attention_plate"
                | translate
                  : {
                      Default:
                        "Informe parte ou a placa completa. (De 2 a 7 caracteres)"
                    }
            }}.
          </mat-error>
        </mat-form-field>

        <!-- <mat-form-field
          appearance="outline"
          *ngIf="form.value.filter_type == 12 || form.value.filter_type == 13"
        >
          <mat-label>{{
            "Business.select_column"
              | translate: { Default: "Selecione a coluna" }
          }}</mat-label>
          <mat-select
            formControlName="column_date"
            [placeholder]="
              'Business_model.date_column'
                | translate: { Default: 'Coluna de datas' }
            "
          >
            <mat-option *ngFor="let item of columnDate" [value]="item.data">
              {{ item.title }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <button mat-button color="primary" (click)="addFilter()">
          <span class="material-icons"> add </span>
          {{ "Business_model.add" | translate: { Default: "Adicionar" } }}
        </button>
      </form>
    </div>
    <mat-card>
      <div *ngIf="noFilters" class="tkg-info">
        <p>
          <mat-icon
            aria-hidden="false"
            aria-label="Info"
            fontIcon="info"
          ></mat-icon>
          <span class="tkg-info-txt"
            >Adicione pelo menos um filtro para gerar o relatório!</span
          >
        </p>
      </div>
      <span
        class="tkg-chips tkg-chips-txt mat-chip-with-trailing-icon"
        *ngFor="let item of active_filters; index as idx"
      >
        <b class="tkg-chips-filter-name">{{ item.filter }}</b
        >{{ item.value }}
        <span
          class="material-icons tkg-chips-icon"
          (click)="
            deleteFilter(idx, item.altName); reportDownloadBtnDesactive()
          "
        >
          cancel
        </span>
      </span>
    </mat-card>
    <div class="tkg-row">
      <button
        mat-flat-button
        color="primary"
        style="color: white"
        (click)="sendReportRequest()"
        [disabled]="blockGenerateReport || noFilters"
        *ngIf="!blockGenerateReport"
      >
        Gerar Relatório
      </button>
      <button
        mat-flat-button
        color="primary"
        style="color: white"
        (click)="sendReportRequest()"
        [disabled]="blockGenerateReport"
        *ngIf="blockGenerateReport"
      >
        Solicitando relatório...
      </button>
    </div>
    <!-- <div class="tkg-row tkg-report-msg" *ngIf="showMessage">
      <p>
        Seu relatório está sendo gerado em segundo plano.<br />Assim que estiver
        pronto, ele será encaminhado para o seu email.
      </p>
    </div> -->
    <p class="tkg-obs">* Relatórios gerados nas últimas 6 horas</p>
    <table class="tkg-table">
      <thead>
        <tr>
          <th>Relatório<br /></th>
          <th>Solicitado em<br /></th>
          <th>Status<br /></th>
          <th style="width: 80px; text-align: center">Ação<br /></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of reportLogsList">
          <td>{{ item.name }}</td>
          <td>{{ item.created_at | date: "short" }}</td>
          <td>{{ reportJobsStatus[item.status] }}</td>
          <td>
            <button
              mat-flat-button
              disabled
              *ngIf="item.status === 1"
              ngClass="spinner"
            ></button>
            <button mat-flat-button disabled *ngIf="item.status === 4">
              Erro
            </button>
            <a
              mat-flat-button
              color="primary"
              [href]="item.link"
              target="_blank"
              *ngIf="[2, 3].includes(item.status)"
              style="color: #fff"
              >Baixar</a
            >
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card-content>
</mat-card>
