import { NgFor } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import {
  MatFormFieldModule,
  MatFormFieldControl,
} from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import {
  FormlyFormOptions,
  FormlyFieldConfig,
  FormlyModule,
} from "@ngx-formly/core";
import { FormlyJsonschema } from "@ngx-formly/core/json-schema";

@Component({
  selector: "app-din-filters-dialog",
  templateUrl: "./din-filters-dialog.component.html",
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgFor,
    FormlyModule,
  ],
  styleUrls: ["./din-filters-dialog.component.scss"],
})
export class DinFiltersDialogComponent implements OnInit {
  ftList: any[] = [
    { id: 1, name: "Motorista", ft_ref_id: "driver_id" },
    { id: 2, name: "Transportadora", ft_ref_id: "carrier_id" },
    { id: 3, name: "Tipo de veículo", ft_ref_id: "vehicle_type_id" },
  ];
  form: FormGroup;
  model: any;
  options: FormlyFormOptions;
  fields: FormlyFieldConfig[];

  type: string = "simple";

  // fields: FormlyFieldConfig[] = [
  //   {
  //     fieldGroupClassName: "tkg-row",
  //     fieldGroup: [
  //       {
  //         key: "filter",
  //         type: "select",
  //         props: {
  //           change: (field, event) => {
  //             this.change();
  //           },
  //           label: "Escolha um filtro",
  //           options: [
  //             { label: "Motorista", value: "driver_id" },
  //             { label: "Transportadora", value: "carrier_id" },
  //             { label: "Operação", value: "operation_id" },
  //             { label: "Suboperação", value: "suboperation_id" },
  //             { label: "Status", value: "status" },
  //           ],
  //         },
  //         className: "tkg-50",
  //       },
  //     ],
  //   },
  // ];

  constructor(private formlyJsonschema: FormlyJsonschema) {}

  ngOnInit(): void {
    // this.fields = [...this.defaultField];
    this.startForm();
  }

  startForm() {
    // const form =     {
    //   fieldGroupClassName: "tkg-row",
    //   fieldGroup: [
    //     {
    //       key: "filter",
    //       type: "select",
    //       props: {
    //         change: (field, event) => {
    //           this.change();
    //         },
    //         label: "Escolha um filtro",
    //         options: [
    //           { label: "Motorista", value: "driver_id" },
    //           { label: "Transportadora", value: "carrier_id" },
    //           { label: "Operação", value: "operation_id" },
    //           { label: "Suboperação", value: "suboperation_id" },
    //           { label: "Status", value: "status" },
    //         ],
    //       },
    //       className: "tkg-50",
    //     },
    //   ],
    // }
    const schema: any = {
      schema: {
        title: "Filtros",
        // description: "A simple form example.",
        type: "object",
        required: ["firstName", "lastName"],

        properties: {
          widget: { formlyConfig: { fieldGroupClassName: "tkg-row" } },
          firstName: {
            type: "object",
            widget: { formlyConfig: { className: "tkg-50" } },
            properties: {
              "Do you have any pets?": {
                type: "string",
                title: "Do you have any pets?",
                enum: ["No", "Yes: One", "Yes: More than one"],
                default: "No",
              },
            },
          },
          lastName: {
            type: "string",
            widget: {
              formlyConfig: {
                type: "enum",
                props: {
                  options: [
                    {
                      value: "1",
                      label: "option 1",
                    },
                    {
                      value: "2",
                      label: "option 2",
                    },
                    {
                      value: "3",
                      label: "option 3",
                    },
                  ],
                },
              },
            },
          },
        },
      },
      model: {},
    };
    this.type = this.type;
    this.form = new FormGroup({});
    this.options = {};
    this.fields = [this.formlyJsonschema.toFieldConfig(schema.schema)];
    this.model = schema.model;
  }

  change() {
    // const filter = this.form.get("filter");
    // console.log(filter?.value);
    // const outro: FormlyFieldConfig = {
    //   key: "carrier_id",
    //   type: "select",
    //   props: {
    //     label: "Escolha uma transportadora",
    //     options: [
    //       { label: "Motorista", value: "driver_id" },
    //       { label: "Transportadora", value: "carrier_id" },
    //       { label: "Operação", value: "operation_id" },
    //       { label: "Suboperação", value: "suboperation_id" },
    //       { label: "Status", value: "status" },
    //     ],
    //   },
    //   className: "tkg-50",
    // };
    // // this.fields.
    // // this.fields.map((item) => {
    // //   item.fieldGroup?.concat(outro);
    // // });
    // // this.fields.at(0)?.fieldGroup?.push(outro);
    // // this.fields[0].fieldGroup?.splice(1, 0, outro);
  }

  add(name = "", email = "") {
    // let filters = this.form.get("filters") as FormArray;
    // filters.push(
    //   this.formBuilder.group({
    //     name: new FormControl(name, [Validators.required]),
    //     email: new FormControl(email, [Validators.required]),
    //   }),
    // );
  }

  remove(i: any) {}
}
